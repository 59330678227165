import i18n from 'helpers/i18n';
import { useState } from 'react';
import messages from './messages';
import { Input } from 'semantic-ui-react';
import Settings from 'images/settings.svg';
import RouteHelpers from 'helpers/routes';
import useCurrentUser from 'hooks/useCurrentUser';
import { toastError } from 'components/Toast';
import { useNavigate } from 'react-router-dom';
import EducatorResourcesMenu from 'components/LearningResources/EducatorResourcesMenu';
import { isEmpty } from 'ramda';

function EducatorHomePage() {
  const navigate = useNavigate();
  const { roles } = useCurrentUser();
  const [query, setQuery] = useState('');

  function isCoachOnly() {
    return roles.includes('lr_coach') && !roles.includes('lr_educator');
  }

  function goToPreferencesPage() {
    if (isCoachOnly()) {
      toastError({ description: i18n.ft(messages.notAllowed) });
      return;
    }

    return navigate(RouteHelpers.getPath('learning-resources-preferences'));
  }

  return (
    <>
      <div className="mb-16 px-4 md:px-0 md:py-8">
        <div className="w-50 flex mb-6">
          <Input
            fluid
            id="search"
            value={query}
            className="w-[322px] md:w-[588px]"
            onChange={(e, data) => setQuery(data.value)}
            placeholder={i18n.ft(messages.searchPlaceholder)}
            icon={
              isEmpty(query)
                ? 'search'
                : { link: true, name: 'times', onClick: () => setQuery('') }
            }
          />
          <span
            className={`ml-4 inline-block content-center ${isCoachOnly() ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            onClick={() => goToPreferencesPage()}
          >
            <img src={Settings} alt="Preferences" width={20} />
          </span>
        </div>

        <EducatorResourcesMenu query={query} setQuery={setQuery} />
      </div>
    </>
  );
}

export default EducatorHomePage;
