import qs from 'query-string';
import debounce from 'debounce';
import i18n from 'helpers/i18n';
import messages from './messages';
import PageLoader from 'components/PageLoader';
import { useNavigate, useLocation } from 'react-router-dom';
import { roleToText } from 'helpers/role';
import { Table } from 'components/Organization/Table';
import { Pagination } from 'components/Organization/Pagination';
import { PrimaryButton } from 'components/Organization/PrimaryButton';
import { useGetAccountMembers } from 'actions/accounts';
import { composeHierarchy, composeUser, formatDate } from './utils';
import { useRef, useState } from 'react';
import RolePopup from 'components/Organization/RolePopup';

import {
  HierarchyModal,
  useAccountHierarchy
} from 'components/Organization/Hierarchy';
import { Checkbox } from '@headlessui/react';
import ActionPopup from 'components/Organization/ActionPopup';
import useCurrentUser from 'hooks/useCurrentUser';
import { useGetAccountsByArea } from 'actions/accountManager/accounts';
import { getCurrentAccount } from 'components/Organization/AccountManager/utils';

const AREA = 'ManageOrganization';

function Members() {
  const currentUser = useCurrentUser();
  const { data: dataAccountByArea = [] } = useGetAccountsByArea(AREA);
  const accountId = getCurrentAccount(
    currentUser.current_account_id,
    dataAccountByArea
  );
  const location = useLocation();
  const { nodeId } = location.state || [];
  const hierarchy = useAccountHierarchy(accountId);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortDir, setSortDir] = useState<'asc' | 'desc' | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isHierarchyModalOpen, setIsHierarchyModalOpen] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedNodes, setSelectedNodes] = useState<number[]>(nodeId || []);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const filterButtonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  const handleFilterButtonClick = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
    filterButtonRef.current?.focus();
  };

  const handleFilterHierarchyButtonClick = () => {
    setIsHierarchyModalOpen(!isHierarchyModalOpen);
  };

  const handleRolesChange = (newRoles: string[]) => {
    setSelectedRoles(newRoles);
  };

  const clearRoles = () => {
    setSelectedRoles([]);
  };

  const clearNodes = () => {
    setSelectedNodes([]);
  };

  const handleNodeSelectionChange = (nodes: number[]) => {
    setSelectedNodes(nodes);
    setIsHierarchyModalOpen(false);
  };

  const handleRowSelect = (userId: number) => {
    setSelectedRows(prevSelectedRows =>
      prevSelectedRows.includes(userId)
        ? prevSelectedRows.filter(id => id !== userId)
        : [...prevSelectedRows, userId]
    );
  };

  const handleHeaderSelect = () => {
    if (!data || !data.users) return;

    if (selectedRows.length <= 0) {
      const allUserIdsOnPage = data.users.map(user => user.id);
      setSelectedRows(allUserIdsOnPage);
    } else {
      setSelectedRows([]);
    }
  };

  const sortMapping: { [key: string]: string } = {
    user: 'user_name',
    name: 'user_name',
    email: 'user_email',
    last_login: 'user_last_login'
  };

  const baseParams = {
    query: searchQuery,
    sort_by: sortBy && sortBy in sortMapping ? sortMapping[sortBy] : sortBy,
    sort_dir: sortDir,
    role_id: selectedRoles,
    node_id: selectedNodes
  };

  const tableParams = {
    ...baseParams,
    per_page: 20,
    page
  };

  const { data, isLoading, refetch } = useGetAccountMembers(
    accountId,
    tableParams
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(1);
  };

  const handleSort = (header: string) => {
    if (header === sortBy && sortDir === 'asc') {
      setSortDir('desc');
    } else if (header === sortBy && sortDir === 'desc') {
      setSortBy(null);
      setSortDir(null);
    } else {
      setSortBy(header);
      setSortDir('asc');
    }
  };

  const onArchiveSuccess = () => {
    refetch();
    setSelectedRows([]);
  };

  const tableHeaders = data?.hierarchy_present
    ? [
        {
          content: (
            <Checkbox
              checked={selectedRows.length > 0}
              onChange={handleHeaderSelect}
              className="text-xl text-action"
            >
              {({ checked }) =>
                checked ? (
                  <i className="fa-solid fa-square-minus" />
                ) : (
                  <i className="fa-regular fa-square" />
                )
              }
            </Checkbox>
          ),
          accessor: 'checkbox',
          filter: (
            <ActionPopup
              accountId={accountId}
              values={selectedRows}
              onSuccess={onArchiveSuccess}
            />
          )
        },
        {
          content: i18n.ft(messages.nameAndEmail),
          accessor: 'user',
          sortable: true
        },
        {
          content: i18n.ft(messages.hierarchyAssociation),
          accessor: 'hierarchy',
          className: 'max-w-md',
          filter: (
            <div className="flex gap-2">
              <button
                onClick={handleFilterHierarchyButtonClick}
                className={`font-bold ${isHierarchyModalOpen ? 'text-action' : 'text-black'}`}
                aria-haspopup="true"
                aria-expanded={isHierarchyModalOpen}
                aria-label={i18n.ft(messages.filterHierarchy)}
              >
                <i className="fa-solid fa-bars-filter fa-sm"></i>
              </button>
              {isHierarchyModalOpen && (
                <HierarchyModal
                  {...hierarchy}
                  title={i18n.ft(messages.filterHierarchy)}
                  buttonText={i18n.ft(messages.saveButton)}
                  isOpen={isHierarchyModalOpen}
                  selectedNodes={selectedNodes}
                  onSave={handleNodeSelectionChange}
                  onClose={handleFilterHierarchyButtonClick}
                />
              )}
              {selectedNodes.length > 0 && (
                <div>
                  <button
                    onClick={clearNodes}
                    className="text-action cursor-pointer"
                    aria-label={i18n.ft(messages.clearNodes)}
                  >
                    <i className="fa-regular fa-circle-xmark fa-sm" />
                  </button>
                </div>
              )}
            </div>
          )
        },
        {
          content: i18n.ft(messages.role),
          accessor: 'role',
          filter: (
            <div className="flex gap-2">
              <button
                ref={filterButtonRef}
                onClick={handleFilterButtonClick}
                className={`font-bold ${
                  isPopupOpen ? 'text-action' : 'text-black'
                }`}
                aria-haspopup="true"
                aria-expanded={isPopupOpen}
                aria-label={i18n.ft(messages.filterRoles)}
              >
                <i className="fa-solid fa-bars-filter fa-sm"></i>
              </button>
              {isPopupOpen && (
                <RolePopup
                  accountId={accountId}
                  isOpen={isPopupOpen}
                  onClose={handlePopupClose}
                  onChange={handleRolesChange}
                  selectedValues={selectedRoles}
                />
              )}
              {selectedRoles.length > 0 && (
                <div>
                  <button
                    onClick={clearRoles}
                    className="text-action cursor-pointer"
                    aria-label={i18n.ft(messages.clearRoles)}
                  >
                    <i className="fa-regular fa-circle-xmark" />
                  </button>
                </div>
              )}
            </div>
          )
        },
        {
          content: i18n.ft(messages.lastLogin),
          accessor: 'last_login',
          sortable: true
        }
      ]
    : [
        {
          content: (
            <Checkbox
              checked={selectedRows.length > 0}
              onChange={handleHeaderSelect}
              className="text-xl text-action"
            >
              {({ checked }) =>
                checked ? (
                  <i className="fa-solid fa-square-minus" />
                ) : (
                  <i className="fa-regular fa-square" />
                )
              }
            </Checkbox>
          ),
          accessor: 'checkbox',
          filter: (
            <ActionPopup
              accountId={accountId}
              values={selectedRows}
              onSuccess={onArchiveSuccess}
            />
          )
        },
        { content: i18n.ft(messages.name), accessor: 'name', sortable: true },
        { content: i18n.ft(messages.email), accessor: 'email', sortable: true },
        { content: i18n.ft(messages.role), accessor: 'role' },
        {
          content: i18n.ft(messages.lastLogin),
          accessor: 'last_login',
          sortable: true
        }
      ];

  const tableData = data?.users.map(user => {
    return data?.hierarchy_present
      ? {
          selected: selectedRows.includes(user.id),
          checkbox: (
            <Checkbox
              checked={selectedRows.includes(user.id)}
              onChange={() => handleRowSelect(user.id)}
              className="text-xl text-action"
            >
              {({ checked }) =>
                checked ? (
                  <i className="fa-solid fa-square-check" />
                ) : (
                  <i className="fa-regular fa-square" />
                )
              }
            </Checkbox>
          ),
          user: composeUser(user.id, user.name, user.email),
          hierarchy: composeHierarchy(user.hierarchy),
          role: roleToText(user.role),
          last_login: formatDate(user.last_login)
        }
      : {
          selected: selectedRows.includes(user.id),
          checkbox: (
            <Checkbox
              checked={selectedRows.includes(user.id)}
              onChange={() => handleRowSelect(user.id)}
              className="text-xl text-action"
            >
              {({ checked }) =>
                checked ? (
                  <i className="fa-solid fa-square-check" />
                ) : (
                  <i className="fa-regular fa-square" />
                )
              }
            </Checkbox>
          ),
          name: composeUser(user.id, user.name),
          email: user.email,
          role: roleToText(user.role),
          last_login: formatDate(user.last_login)
        };
  });

  const membersCount = data?.pagination.total_count;

  const membersCsvUrl = qs.stringifyUrl(
    {
      url: `/api/v2/ca/accounts/${accountId}/members.csv`,
      query: baseParams
    },
    { arrayFormat: 'bracket' }
  );

  return (
    <div className="my-8 relative">
      <div className="bg-[#0A9CCA] -mx-6 xl:-mx-14 left-0 right-0 h-52 rounded-b-2xl" />
      <div className="-mt-44 p-6 pb-12 bg-white rounded-2xl">
        <div className="flex flex-col">
          <div>
            <h2 className="font-sans font-bold text-3xl">
              {i18n.ft(messages.members)}
            </h2>
          </div>
          <div className="mt-1 flex justify-between items-center">
            <a
              className="flex items-center gap-2 font-semibold text-sm text-action"
              href={membersCsvUrl}
              download
            >
              <i className="fa-solid fa-download" />
              <span>{i18n.ft(messages.downloadMemberList)}</span>
            </a>

            <div className="flex gap-6">
              <input
                type="search"
                className="border rounded-md max-w-94 min-w-80 h-10 p-2 placeholder:font-sans"
                onChange={debounce(handleSearch, 300)}
                placeholder={i18n.ft(messages.searchPlaceholder)}
                aria-label={i18n.ft(messages.searchPlaceholder)}
              />

              <PrimaryButton
                icon="fa-solid fa-plus"
                onClick={() => navigate('/organization/add-members')}
              >
                {i18n.ft(messages.addMembers)}
              </PrimaryButton>
            </div>
          </div>
        </div>
        {isLoading ? (
          <PageLoader />
        ) : (
          <>
            {tableData && (
              <>
                <div className="text-[#3C3F42] mt-6 mb-1.5 text-sm">
                  {i18n.ft(messages.memberCount, { membersCount })}
                </div>

                <Table
                  headers={tableHeaders}
                  data={tableData}
                  sortBy={sortBy}
                  sortDir={sortDir}
                  onSort={handleSort}
                />

                <div className="mt-6 flex justify-center">
                  <Pagination
                    page={page}
                    onPageChange={setPage}
                    total={data?.pagination.total_pages ?? 0}
                    label={i18n.ft(messages.totalMembers, {
                      count: membersCount
                    })}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Members;
