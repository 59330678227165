import i18n from 'helpers/i18n';
import messages from './messages';
import Title from 'design-system/Title';
import { TrainingList } from 'components/TrainingHistory/TrainingList';
import { TrainingSummary } from 'components/TrainingHistory/TrainingSummary';
import DateRangeModal from 'components/TrainingHistory/DateRangeModal';
import { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { LearningResources } from 'components/TrainingHistory/LearningResources';

function TrainingHistoryPage() {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isCustom, setIsCustom] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  function getCurrentSchoolYear() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const julyFirst = new Date(currentYear, 6, 1);

    let startYear, endYear;

    if (today >= julyFirst) {
      startYear = currentYear;
      endYear = currentYear + 1;
    } else {
      startYear = currentYear - 1;
      endYear = currentYear;
    }

    const start = new Date(startYear, 6, 1);
    const end = new Date(endYear, 5, 30);

    return { start, end };
  }

  useEffect(() => {
    const { start, end } = getCurrentSchoolYear();
    setStartDate(start);
    setEndDate(end);
  }, []);

  const onSaved = (newStartDate: Date, newEndDate: Date, isCustom: boolean) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setIsCustom(isCustom);
    setIsOpen(false);
  };

  if (!startDate || !endDate) return <Loader active />;

  return (
    <>
      <DateRangeModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={onSaved}
        initialStartDate={startDate!}
        initialEndDate={endDate!}
        getCurrentSchoolYear={getCurrentSchoolYear}
      />
      <main className="max-w-7xl mx-auto font-sans text-black">
        <Title>{i18n.ft(messages.title)}</Title>

        <div className="flex pb-10 text-base">
          <span>
            {i18n.ft(messages.timeframe) + ' '}
            <button
              className="text-base text-[#1C778C]"
              onClick={() => setIsOpen(true)}
            >
              {isCustom ? i18n.ft(messages.custom) : i18n.ft(messages.current)}
              <div className="fa-solid fa-gear text-[#1C778C] fa-sm pl-1"></div>
            </button>
          </span>
        </div>

        <div className="flex flex-col gap-16">
          <TrainingSummary startDate={startDate} endDate={endDate} />
          <LearningResources startDate={startDate} endDate={endDate} />
          <TrainingList startDate={startDate} endDate={endDate} />
        </div>
      </main>
    </>
  );
}

export default TrainingHistoryPage;
