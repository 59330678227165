const PATH = 'fe.components.training_history.date_range_modal';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Change Date Range'
  },
  subtitle: {
    key: `${PATH}.subtitle`,
    defaultValue:
      'The timeframe you select here will filter the data you see on your Professional Development dashboard.'
  },
  current: {
    key: `${PATH}.current`,
    defaultValue: 'Current School Year'
  },
  custom: {
    key: `${PATH}.custom`,
    defaultValue: 'Custom'
  },
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  },
  update: {
    key: `${PATH}.update`,
    defaultValue: 'Update'
  }
};

export default messages;
