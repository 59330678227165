import { useEffect } from 'react';
import RoutesHelper from 'helpers/routes';
import useCurrentUser from 'hooks/useCurrentUser';
import DetailPage from '../LearningResources/DetailPage';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AllResourcesPage from '../LearningResources/AllResourcesPage';
import PlaylistDetailPage from 'pages/LearningResources/Playlist/PlaylistDetailPage';
import LearningResourcesProvider from '../LearningResources/LearningResourcesContext';
import LearningResourcesHeader from 'components/LearningResources/LearningResouresHeader';
import Recommend from './Recommend';
import MyEducators from 'components/MyEducators';
import { ClassroomProvider } from './Recommend/ClassroomContext';

function Coach() {
  const user = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.roles.includes('lr_coach')) {
      navigate(RoutesHelper.getPath('learning-resources'));
    }
  }, [user, navigate]);

  if (!user.roles.includes('lr_coach')) {
    return null;
  }

  return (
    <LearningResourcesProvider>
      <Routes>
        <Route element={<LearningResourcesHeader userId={user.id} />}>
          <Route path="see-all" element={<AllResourcesPage />} />
          <Route path=":id" element={<DetailPage />} />
          <Route path="playlist/:id" element={<PlaylistDetailPage />} />
          <Route
            path="recommend"
            element={
              <ClassroomProvider>
                <Recommend />
              </ClassroomProvider>
            }
          />
          <Route index element={<MyEducators />} />
        </Route>
      </Routes>
    </LearningResourcesProvider>
  );
}

export default Coach;
