const PATH = 'fe.components.organization.hierarchy';

const messages = {
  hierarchySearch: {
    placeholder: {
      key: `${PATH}.hierarchy_search.placeholder`,
      defaultValue: 'Search hierarchy level'
    }
  },
  clearAll: {
    key: `${PATH}.clear_all`,
    defaultValue: 'Clear all'
  },
  invalidTagName: {
    key: `${PATH}.invalid_tag_name`,
    defaultValue: 'Invalid Node'
  }
};

export default messages;
