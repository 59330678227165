// TODO: Implement Custom Popup component. Use semantic for now.
import { Popup } from 'semantic-ui-react';

interface IconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  icon: string;
  label?: string;
}

export function IconButton({ label, icon, ...props }: IconButtonProps) {
  if (!label) {
    return (
      <button
        {...props}
        className="w-8 h-8 flex items-center justify-center rounded text-action hover:bg-action-tint"
      >
        <i className={icon} />
      </button>
    );
  }

  return (
    <Popup
      position="top center"
      className="before:!bg-[#3C3F42] before:!border-0 before:!shadow-none"
      style={{
        padding: '4px 8px',
        border: 0,
        boxShadow: 'none',
        background: '#3C3F42'
      }}
      content={<span className="font-sans text-sm text-white">{label}</span>}
      trigger={
        <button
          {...props}
          className="w-8 h-8 flex items-center justify-center rounded text-action hover:bg-action-tint"
        >
          <i className={icon} />
        </button>
      }
    />
  );
}
