import i18n from 'helpers/i18n';
import messages from './messages';
import { Outlet } from 'react-router-dom';
import RouteHelpers from 'helpers/routes';
import useCurrentUser from 'hooks/useCurrentUser';
import Title from 'design-system/Title';
import rhombus from 'images/rhombus.svg';

function EducatorHeader() {
  const currentUser = useCurrentUser();

  function isCoach() {
    return currentUser.roles.includes('lr_coach');
  }

  return (
    <>
      <main
        className=" mx-auto my-10 px-4 2xl:px-0 min-h-[calc(100vh-420px)]"
        style={{ maxWidth: '1360px' }}
      >
        {isCoach() && (
          <div className="w-full mt-4 p-4 bg-[#cbe3ed] rounded mb-4">
            <div className="flex justify-between">
              <div>{i18n.ft(messages.educatorView)}</div>
              <div className="font-bold text-[#0A9CCA] cursor-pointer">
                <a href={RouteHelpers.getPath('coach-learning-resources')}>
                  {`< ${i18n.ft(messages.back)}`}
                </a>
              </div>
            </div>
          </div>
        )}
        <div className="px-4 md:px-0 text-black">
          <Title>{i18n.ft(messages.title)}</Title>
        </div>
        <Outlet />
      </main>
      <div className="md:px-0 -mb-8">
        <img src={rhombus} alt="Rhombus" className="w-full" />
      </div>
    </>
  );
}

export default EducatorHeader;
