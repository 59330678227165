import i18n from 'helpers/i18n';
import messages from './messages';
import { Link } from 'react-router-dom';
import { EditableNode } from 'components/Organization/EditHierarchy/EditableNode';

import {
  NodeList,
  HierarchyProvider
} from 'components/Organization/HierarchyBuilder';

function EditHierarchy() {
  return (
    <div className="px-4 md:px-6 xl:px-10 max-w-7xl mx-auto">
      <div className="pt-6 md:pb-4 lg:pb-6">
        <Link
          to="/organization/hierarchy"
          className="font-sans font-semibold text-sm text-action hover:text-action hover:underline"
        >
          <i className="fa-solid fa-arrow-left no-underline" />
          <span className="ml-2">{i18n.ft(messages.backToHierarchy)}</span>
        </Link>
      </div>

      <h2 className="font-sans font-bold text-black text-3xl py-4">
        {i18n.ft(messages.header)}
      </h2>

      <div className="bg-white rounded-2xl px-6 py-4">
        <NodeList NodeComponent={EditableNode} />
      </div>
    </div>
  );
}

function EditHierarchyController() {
  return (
    <HierarchyProvider area="ManageOrganization">
      <EditHierarchy />
    </HierarchyProvider>
  );
}

export default EditHierarchyController;
