import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react';

interface CollapsibleSummaryProps {
  label: string;
  total: number;
  breakdownList: { text: string; value: number }[];
}

// Helper function to format hours
// Show 0 as "0"
// Show anything > 0.0 and < 0.1 as "<0.1"
// Show anything else as a number with 1 decimal place
function formatHours(timeInHours: number) {
  if (timeInHours == 0) {
    return '0';
  } else if (timeInHours >= 0.1) {
    return timeInHours.toFixed(1);
  } else {
    return '<0.1';
  }
}

export function CollapsibleSummary({
  label,
  total,
  breakdownList
}: CollapsibleSummaryProps) {
  // Adjust width with offset based on total to align the breakdown values to the right
  const widthOffset =
    total - Math.ceil(total) > 10 || Math.trunc(total) > 10 ? 80 : 72;

  // This really shouldn't be necessary, because in theory `total` is a Number already, but
  // for some reason while in theory it is, in practice it ain't, and it is easier to coerce here
  // than to fix whatever oddity causes it to be a string.
  const totalString = formatHours(Number(total));

  return (
    <Disclosure>
      <DisclosureButton className="w-full px-6 py-4 bg-white shadow-sm rounded-lg flex items-center justify-between data-[open]:rounded-b-none">
        {({ open }) => (
          <>
            <span className="font-sans text-xl font-bold">{label}</span>
            <div className="flex items-center gap-8">
              <span className="font-sans text-xl font-bold">{totalString}</span>
              {open ? (
                <i className="fa-solid fa-caret-up" />
              ) : (
                <i className="fa-solid fa-caret-down" />
              )}
            </div>
          </>
        )}
      </DisclosureButton>
      <DisclosurePanel className="rounded-lg shadow-sm">
        {breakdownList.map((item, index) => (
          <div
            key={index}
            className="bg-white flex items-center justify-between px-6 py-3 border-b border-navy-100 first:border-t last:border-b-0 last:rounded-b-lg"
          >
            <span>{item.text}</span>
            <span style={{ width: widthOffset }}>
              {formatHours(Number(item.value))}
            </span>
          </div>
        ))}
      </DisclosurePanel>
    </Disclosure>
  );
}
