import { clsx } from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
import { AccountHierarchy } from 'types/api/AccountHierarchy';

import {
  useNode,
  Node,
  NodeName,
  NodeChildren,
  NodeDisclosureButton
} from 'components/Organization/HierarchyBuilder';

type URLParams = {
  nodeId: string;
};

export function HierarchyNode() {
  const { node, depth } = useNode();
  const navigate = useNavigate();
  const params = useRouteParams<URLParams>();
  const isActive = parseInt(params.nodeId) === node.id;

  const handleNodeClick = (node: AccountHierarchy) => {
    navigate(`/organization/hierarchy/${node.id}`);
  };

  const classes = clsx(
    isActive && 'bg-action-tint hover:bg-action-tint focus:bg-action-tint'
  );

  return (
    <>
      <Node onClick={handleNodeClick} className={classes}>
        <div className="flex items-center gap-3">
          {depth > 1 && <i className="fa-solid fa-arrow-turn-down-right" />}
          <NodeName />
        </div>

        <NodeDisclosureButton />
      </Node>
      <NodeChildren />
    </>
  );
}
