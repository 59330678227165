const PATH = 'fe.components.learning_resources.header';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Learning Resources'
  },
  home: {
    key: `${PATH}.home`,
    defaultValue: 'Home'
  },
  browseResources: {
    key: `${PATH}.browse_resources`,
    defaultValue: 'Browse Resources'
  },
  recommend: {
    key: `${PATH}.recommend`,
    defaultValue: 'Recommend'
  },
  resources: {
    key: `${PATH}.resources`,
    defaultValue: 'Resources'
  },
  educator: {
    key: `${PATH}.educator`,
    defaultValue: 'Login as Educator'
  }
};

export default messages;
