import i18n from 'helpers/i18n';
import messages from './messages';
import PageLoader from 'components/PageLoader';
import useCurrentUser from 'hooks/useCurrentUser';
import { useGetGrades } from 'actions/grades';
import { useNavigate } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
import { useQueryClient } from '@tanstack/react-query';
import { Input } from 'components/Organization/Input';
import { toastError } from 'components/Organization/Toast';
import { AccountNode, Tagging } from 'types/api/AccountNode';
import { parseAPIErrorsNoKeys } from 'components/ErrorModal';
import { EditableField } from 'components/Organization/EditableField';
import { Select, SelectOption } from 'components/Organization/Select';
import { SecondaryButton } from 'components/Organization/SecondaryButton';

import {
  updateAccountNode,
  updateAccountNodeTags,
  useGetAccountNode,
  useGetTagsByGroup
} from 'actions/accounts';

function formatTaggings(taggings: Tagging[]) {
  return taggings.map(tagging => tagging.tag.name).join(', ');
}

function formatTaggingValues(taggings: Tagging[]) {
  return taggings.map(tagging => tagging.tag.id);
}

interface HierarchyNodeDetailsProps {
  /** Account node data. */
  node: AccountNode;

  /** Current Account ID. */
  accountId: number;

  /** List of funding streams. */
  fundingStreams: SelectOption[];

  /** List of special populations. */
  specialPopulations: SelectOption[];

  /** List of program descriptors. */
  programDescriptors: SelectOption[];

  /** List of grades. */
  grades: SelectOption[];
}

export function HierarchyNodeDetails({
  node,
  accountId,
  grades,
  fundingStreams,
  specialPopulations,
  programDescriptors
}: HierarchyNodeDetailsProps) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleTaggingSave = async (field: string, value: any) => {
    const response = await updateAccountNodeTags(accountId, node.id, {
      [field]: value
    });

    queryClient.setQueryData(
      ['ca', 'accounts', accountId, 'nodes', node.id],
      response.data
    );
  };

  const handleFieldSave = async (field: string, value: any) => {
    return updateAccountNode(accountId, node.id, {
      node: { ...node, [field]: value }
    }).then(
      response => {
        queryClient.setQueryData(
          ['ca', 'accounts', accountId, 'nodes', node.id],
          response.data
        );

        if (field === 'name') {
          queryClient.invalidateQueries({
            queryKey: ['accounts', accountId, 'hierarchy']
          });
        }
      },
      error => {
        const errors = parseAPIErrorsNoKeys(error.response.data.errors);
        toastError({ message: errors.join(', ') });
        return false;
      }
    );
  };

  function handleViewMembersClick() {
    const nodeId = [node?.id];
    navigate('/organization/members', { state: { nodeId } });
  }

  return (
    <div key={node.id}>
      <div className="flex justify-between">
        <h3 className="text-2xl">{i18n.ft(messages.details)}</h3>
        <SecondaryButton onClick={handleViewMembersClick} size="small">
          {i18n.ft(messages.viewMembers)}
        </SecondaryButton>
      </div>

      <EditableField
        border={false}
        label={i18n.ft(messages.name)}
        value={node.name}
        initialValue={node.name}
        onSave={value => handleFieldSave('name', value)}
        control={({ value, setValue }) => (
          <Input
            value={value}
            onChange={event => setValue(event.target.value)}
            label={i18n.ft(messages.name)}
          />
        )}
      />

      <EditableField
        label={i18n.ft(messages.hierarchy)}
        value={node.ancestry.map(a => a.name).join(' / ')}
      />

      <EditableField
        label={i18n.ft(messages.externalId)}
        value={node.external_account_id}
        initialValue={node.external_account_id || ''}
        onSave={value => handleFieldSave('external_account_id', value)}
        control={({ value, setValue }) => (
          <Input
            value={value}
            onChange={event => setValue(event.target.value)}
            label={i18n.ft(messages.externalId)}
          />
        )}
      />

      <EditableField
        label={i18n.ft(messages.fundingStreams)}
        value={formatTaggings(node.funding_stream_taggings)}
        initialValue={formatTaggingValues(node.funding_stream_taggings)}
        onSave={value => handleTaggingSave('funding_stream_ids', value)}
        control={({ value, setValue }) => (
          <Select
            multiple
            value={value}
            onChange={setValue}
            options={fundingStreams}
            label={i18n.ft(messages.fundingStreams)}
            listboxOptionModal={false}
          />
        )}
      />

      <EditableField
        label={i18n.ft(messages.programDescriptors)}
        value={formatTaggings(node.program_descriptor_taggings)}
        initialValue={formatTaggingValues(node.program_descriptor_taggings)}
        onSave={value => handleTaggingSave('program_descriptors_ids', value)}
        control={({ value, setValue }) => (
          <Select
            multiple
            value={value}
            onChange={setValue}
            options={programDescriptors}
            label={i18n.ft(messages.programDescriptors)}
            listboxOptionModal={false}
          />
        )}
      />

      {node.type === 'Classroom' && (
        <>
          <EditableField
            label={i18n.ft(messages.gradeLevel)}
            value={node.grade_name}
            initialValue={node.grade_id}
            onSave={value => handleFieldSave('grade_id', value)}
            control={({ value, setValue }) => (
              <Select
                value={value}
                onChange={setValue}
                options={grades}
                label={i18n.ft(messages.gradeLevel)}
                listboxOptionModal={false}
              />
            )}
          />

          <EditableField
            label={i18n.ft(messages.specialPopulation)}
            value={formatTaggings(node.population_taggings)}
            initialValue={formatTaggingValues(node.population_taggings)}
            onSave={value => handleTaggingSave('special_population_ids', value)}
            control={({ value, setValue }) => (
              <Select
                multiple
                value={value}
                onChange={setValue}
                options={specialPopulations}
                label={i18n.ft(messages.specialPopulation)}
                listboxOptionModal={false}
              />
            )}
          />
        </>
      )}
    </div>
  );
}

type Params = {
  nodeId: string;
};

function HierarchyNodeDetailsController() {
  const params = useRouteParams<Params>();
  const nodeId = parseInt(params.nodeId);
  const currentUser = useCurrentUser();
  const accountId = currentUser.current_account_id || 0;

  const { data, isPending } = useGetAccountNode({ accountId, nodeId });
  const { data: fs } = useGetTagsByGroup('funding_stream');
  const { data: sp } = useGetTagsByGroup('special_population');
  const { data: pd } = useGetTagsByGroup('program_descriptors');
  const { data: grades } = useGetGrades();

  function formatAsOptions(data: any[] = []): SelectOption[] {
    return data.map(t => ({ value: t.id, text: t.name }));
  }

  if (isPending) {
    return <PageLoader />;
  }

  return (
    <HierarchyNodeDetails
      node={data}
      accountId={accountId}
      grades={formatAsOptions(grades?.grades)}
      fundingStreams={formatAsOptions(fs?.tags)}
      specialPopulations={formatAsOptions(sp?.tags)}
      programDescriptors={formatAsOptions(pd?.tags)}
    />
  );
}

export default HierarchyNodeDetailsController;
