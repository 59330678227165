import i18n from 'helpers/i18n';
import { useCallback, useEffect } from 'react';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { Loader } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { usePaginatedSearch } from 'hooks/usePaginatedSearch';
import {
  LearningResourceSearchResult,
  ResourceType
} from 'types/api/LearningResource';
import {
  getHoursMinutes,
  getResourceRoute
} from 'pages/LearningResources/utils';
import { getAllLearningResources } from 'actions/learningResources';
import VideoResource from 'components/LearningResources/VideoResource';
import useCurrentUser from 'hooks/useCurrentUser';
import PlaylistItem from '../PlaylistItem';

interface SearchResultsProps {
  query: string;
  searchTitle?: string;
  direction?: 'asc' | 'desc';
  sortBy?: string;
}

function SearchResults({
  query,
  searchTitle,
  direction,
  sortBy
}: SearchResultsProps) {
  const DEFAULT_PER_PAGE = 20;
  const navigate = useNavigate();
  const user = useCurrentUser();
  const { data, run, isPending, setData } = useAsync();
  const { state } = usePaginatedSearch({
    page: 1,
    query: query,
    sort_by: sortBy,
    sort_dir: direction,
    per_page: DEFAULT_PER_PAGE
  });

  useEffect(() => {
    run(
      getAllLearningResources({
        ...state,
        query: query,
        sort_by: sortBy,
        sort_dir: direction
      })
    );
  }, [run, state, query, direction, sortBy]);

  useEffect(() => {
    return () => {
      setData(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = useCallback(
    (id: number, resourceType: ResourceType) =>
      navigate(
        getResourceRoute(id, resourceType, user.roles.includes('lr_coach'))
      ),
    [navigate, user]
  );

  if (isPending) {
    return <Loader active />;
  }

  if (data.resources?.length === 0) {
    return (
      <div className="mt-16 w-full mx-auto text-center">
        {i18n.ft(messages.noResults)}
      </div>
    );
  }

  return (
    <>
      {searchTitle ? null : (
        <div className="block md:flex items-center mb-4">
          <h2 className="text-black">{`${i18n.ft(
            messages.results
          )} "${query}"`}</h2>
        </div>
      )}

      <div
        className={`grid grid-cols-1 gap-6 my-2 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4`}
      >
        {data.resources?.map(
          (video: LearningResourceSearchResult, idx: number) =>
            video.type === 'learning_resource' ? (
              <VideoResource
                ariaLabel={'Video Thumbnail'}
                ariaRoleDescription={'Slide'}
                handleClick={handleClick}
                video={{
                  name: video.name,
                  id: video.id ? video.id : idx,
                  videoURI: video.video_uri ? video.video_uri : '1_bkuk1xi7',
                  estimatedTime: video.estimated_time
                    ? getHoursMinutes(video.estimated_time)
                    : '0',
                  type: video.type
                }}
                key={video.id}
                tags={video.tags}
                showAllResources
              />
            ) : (
              <PlaylistItem
                percentage={video.completion_percentage || 0}
                numberOfResources={video.total_resources || 1}
                handleClick={handleClick}
                key={idx}
                video={{
                  name: video.name,
                  id: video.id ? video.id : idx,
                  videoURI: video.video_uri ? video.video_uri : '1_bkuk1xi7',
                  type: video.type
                }}
              />
            )
        )}
      </div>
    </>
  );
}

export default SearchResults;
