const PATH = 'fe.components.training_history.training_summary';

const messages = {
  hours: {
    key: `${PATH}.hours`,
    defaultValue: 'Learning Hours Earned'
  },
  ceus: {
    key: `${PATH}.ceus`,
    defaultValue: 'CEUs Earned'
  },
  pdAccomplishments: {
    key: `${PATH}.pd_accomplishments`,
    defaultValue: 'Your PD Accomplishments'
  },
  onlineCoursework: {
    key: `${PATH}.online_coursework`,
    defaultValue: 'Online Coursework'
  },
  facilitatedTrainings: {
    key: `${PATH}.facilitated_trainings`,
    defaultValue: 'Facilitated Trainings'
  },
  learningResources: {
    key: `${PATH}.learning_resources`,
    defaultValue: 'Learning Resources'
  }
};

export default messages;
