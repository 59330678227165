import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import {
  TrainingHistory,
  TrainingHistorySummary
} from 'types/api/TrainingHistory';

type TrainingHistoryData = {
  training_histories: TrainingHistory[];
  any_training_completed: boolean;
  pagination: {
    total_pages: number;
    current_page: number;
    per_page: number;
    total_count: number;
  };
};

export function getLearningHistory(params = {}) {
  return axios.get<TrainingHistoryData>(`/api/v3/client/training_history`, {
    params
  });
}

export function useGetTrainingHistory(params = {}) {
  return useQuery({
    queryKey: ['client', 'training_history', params],
    queryFn: () => getLearningHistory(params).then(res => res.data)
  });
}

export function getTrainingHistorySummary(params = {}) {
  return axios.get<TrainingHistorySummary>(
    `/api/v3/client/training_history/summary`,
    { params }
  );
}

export function useGetTrainingHistorySummary(params = {}) {
  return useQuery({
    queryKey: ['client', 'training_history', 'summary', params],
    queryFn: () => getTrainingHistorySummary(params).then(res => res.data)
  });
}
