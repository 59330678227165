import i18n from 'helpers/i18n';
import messages from './messages';
import Title from 'design-system/Title';
import useCurrentUser from 'hooks/useCurrentUser';
import OrganizationMembers from 'pages/Organization/Members';
import OrganizationAddMembers from 'pages/Organization/AddMembers';
import OrganizationViewMember from 'pages/Organization/ViewMember';
import OrganizationHierarchy from 'pages/Organization/Hierarchy';
import OrganizationHierarchyNodeView from 'pages/Organization/Hierarchy/NodeView';
import OrganizationEditHierarchy from 'pages/Organization/EditHierarchy';
import AccountManager from 'components/Organization/AccountManager';
import { useGetAccountsByArea } from 'actions/accountManager/accounts';
import { getCurrentAccount } from 'components/Organization/AccountManager/utils';
import { Routes, Route, NavLink, Outlet, Navigate } from 'react-router-dom';
import RoutesHelper from 'helpers/routes';
import clsx from 'clsx';

function OrganizationSwitch() {
  return (
    <Routes>
      <Route element={<OrganizationRoot />}>
        <Route index element={<Navigate replace to="members" />} />

        <Route path="/hierarchy" element={<OrganizationHierarchy />}>
          <Route path=":nodeId" element={<OrganizationHierarchyNodeView />} />
        </Route>

        <Route path="/members">
          <Route index element={<OrganizationMembers />} />
        </Route>
      </Route>
      <Route path="/add-members" element={<OrganizationAddMembers />} />
      <Route path="/members/:memberId" element={<OrganizationViewMember />} />
      <Route path="/edit-hierarchy" element={<OrganizationEditHierarchy />} />
    </Routes>
  );
}

const AREA = 'ManageOrganization';

function OrganizationRoot() {
  const currentUser = useCurrentUser();
  const { data = [], isLoading } = useGetAccountsByArea(AREA);
  const accountId = getCurrentAccount(currentUser.current_account_id, data);

  return (
    <>
      <AccountManager
        area="ManageOrganization"
        accounts={data}
        currentAccountId={accountId}
        isLoadingAccounts={isLoading}
      />

      <main className="px-6 xl:px-14 text-black font-sans">
        <Title>{i18n.ft(messages.manageOrganization)}</Title>

        <div className="flex gap-12 mt-4 border-b border-[#85878A]">
          <NavLink
            to={RoutesHelper.getPath('organization-members')}
            className={({ isActive }) =>
              clsx('flex items-center gap-1.5 hover:text-action', {
                'font-semibold border-b-2 border-action text-action': isActive,
                'text-black': !isActive
              })
            }
          >
            <i className="fa-solid fa-users" />
            <span className="text-base">{i18n.ft(messages.members)}</span>
          </NavLink>

          <NavLink
            to={RoutesHelper.getPath('organization-hierarchy')}
            className={({ isActive }) =>
              clsx('flex items-center gap-1.5 hover:text-action', {
                'font-semibold border-b-2 border-action text-action': isActive,
                'text-black': !isActive
              })
            }
          >
            <i className="fa-solid fa-sitemap" />
            <span className="text-base">{i18n.ft(messages.hierarchy)}</span>
          </NavLink>
        </div>

        {!accountId ? (
          <div className="mt-8">{i18n.ft(messages.selectAccount)}</div>
        ) : (
          <Outlet />
        )}
      </main>
    </>
  );
}

export default OrganizationSwitch;
