export function isBlank(value: any) {
  return value === undefined || value === null || value === '';
}

export function getSortIcon(sorted: boolean, sortDir?: 'asc' | 'desc' | null) {
  const variants = {
    base: {
      '--fa-primary-color': 'black',
      '--fa-secondary-color': 'black',
      '--fa-secondary-opacity': 1
    },
    asc: {
      '--fa-primary-color': '#1C778C',
      '--fa-secondary-color': '#AFBFD2',
      '--fa-secondary-opacity': 1
    },
    desc: {
      '--fa-primary-color': '#AFBFD2',
      '--fa-secondary-color': '#1C778C',
      '--fa-secondary-opacity': 1
    }
  };

  const styles = (
    sorted && sortDir ? variants[sortDir] : variants.base
  ) as React.CSSProperties;

  return <i className="fa-duotone fa-sort" style={styles} />;
}
