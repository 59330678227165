import axios from 'axios';
import { AccountHierarchy } from 'types/api/AccountHierarchy';

export function getCenters(accountId: number) {
  return axios.get(`/api/v2/accounts/${accountId}/nodes/centers`);
}

export function getClassrooms(accountId: number, nodeId: number) {
  return axios.get(`/api/v2/accounts/${accountId}/nodes/${nodeId}/classrooms`);
}

export function updateNode(nodeId: number, node: AccountHierarchy) {
  return axios.put(`/api/v2/nodes/${nodeId}`, {
    node
  });
}

export function addNode(payload = {}) {
  return axios.post('/api/v2/nodes', payload);
}

export function getNode(nodeId: number) {
  return axios.get(`/api/v2/nodes/${nodeId}`);
}

export function deleteNode(nodeId: number) {
  return axios.delete(`/api/v2/nodes/${nodeId}`);
}

export function addTaggingToNode(nodeId: number, tag_id: number) {
  return axios.post(`/api/v2/nodes/${nodeId}/taggings`, {
    tagging: { tag_id }
  });
}

export function deleteNodeTag(nodeId: number, tagId: number, endDate: string) {
  return axios.delete(`/api/v2/nodes/${nodeId}/taggings/${tagId}`, {
    data: { end_date: endDate }
  });
}

export function updateGrade(nodeId: number, params = {}) {
  return axios.put(`/api/v2/nodes/${nodeId}/grades`, { node: params });
}

type NodeUser = {
  name: string;
  email: string;
  role: string;
};

type GetNodeUsersRes = {
  users: NodeUser[];
  pagination: {
    total_pages: number;
    current_page: number;
  };
};

export function getNodeUsers(nodeId: number) {
  return axios.get<GetNodeUsersRes>(`/api/v2/nodes/${nodeId}/users`);
}
