import i18n from 'helpers/i18n';
import messages from './messages';
import { useMutation } from '@tanstack/react-query';
import { archiveUsersToAccount } from 'actions/accounts';
import { toastSuccess } from 'components/Organization/Toast';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

interface PopupProps {
  /** Account id. */
  accountId: number;

  /** List of selected user ids. */
  values: number[];

  /** Callback fired after users are archived. */
  onSuccess: () => void;
}

function ActionPopup({ accountId, values, onSuccess }: PopupProps) {
  const { isPending, mutate: archiveUsers } = useMutation({
    mutationFn: archiveUsersToAccount,
    onSuccess: () => {
      toastSuccess({
        message: i18n.ft(messages.membersArchived)
      });
      onSuccess();
    }
  });

  const handleArchive = () => {
    if (isPending || values.length === 0) {
      return;
    }

    archiveUsers({ accountId, params: { user_ids: values } });
  };

  if (values.length === 0) {
    // If no users are selected, render an empty div to maintain layout.
    return <div className="w-6" />;
  }

  return (
    <Menu>
      <MenuButton className="w-6 -mt-0.5 text-xl flex items-center justify-center">
        <i className="fa-solid fa-caret-down" />
      </MenuButton>
      <MenuItems
        anchor="bottom start"
        className="p-1 absolute flex flex-col bg-white ring-2 ring-[#0A9CCA] rounded-[10px]"
      >
        <MenuItem>
          <button
            className="px-3 py-2 font-sans text-base text-left rounded data-[focus]:bg-action-tint"
            onClick={handleArchive}
          >
            {i18n.ft(messages.archive, { count: values.length })}
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
}

export default ActionPopup;
