import i18n from 'helpers/i18n';
import messages from './messages';
import { useLayoutEffect, useState } from 'react';
import { AccountHierarchy } from 'types/api/AccountHierarchy';
import { HierarchySearch, NodeOption } from './HierarchySearch';
import { TextButton } from 'components/Organization/TextButton';
import { TreeNode } from './TreeNode';
import { TreeNodeTag } from './TreeNodeTag';
import { useNodeCheckboxTree, createHierarchyOptions, NodeMap } from './utils';

import {
  Dialog,
  DialogTitle,
  DialogPanel,
  DialogBackdrop
} from '@headlessui/react';

interface HierarchyModalProps {
  /** The name of the account. */
  account: string | null;

  /** List of hierarchy nodes. */
  nodes: AccountHierarchy[];

  /** Map of node names. */
  nodeNameMap: NodeMap;

  /** Modal title. */
  title: string;

  /** Submit button label. */
  buttonText: string;

  /** Whether the modal is open. */
  isOpen: boolean;

  /** Node ids of current selected nodes. */
  selectedNodes: number[];

  /** Callback fired when clicking the submit button. */
  onSave: (nodes: number[]) => void;

  /** Callback fired when closing the modal. */
  onClose: () => void;
}

export function HierarchyModal({
  nodes,
  nodeNameMap,
  account,
  title,
  buttonText,
  selectedNodes,
  isOpen,
  onSave,
  onClose
}: HierarchyModalProps) {
  const [openNodes, setOpenNodes] = useState<number[]>([]);
  const [highlightedNodeId, setHighlightedNodeid] = useState<number | null>(
    null
  );

  const { nodeMap, updateNode, clearAll, checkedNodeIds } = useNodeCheckboxTree(
    nodes,
    selectedNodes
  );

  useLayoutEffect(() => {
    if (highlightedNodeId === null) {
      return;
    }

    const element = document.querySelector(
      `[data-hierarchy-id="${highlightedNodeId}"]`
    );

    element?.scrollIntoView();
  }, [highlightedNodeId]);

  const handleOpenNode = (nodeId: number) => {
    setOpenNodes(prev => {
      if (prev.includes(nodeId)) {
        return prev.filter(node => node !== nodeId);
      }

      return [...prev, nodeId];
    });
  };

  const handleNodeSelect = (node?: NodeOption) => {
    if (!node) {
      setHighlightedNodeid(null);
      return;
    }

    setOpenNodes(node.treeIds);
    setHighlightedNodeid(node.id);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <div className="min-h-screen text-center font-sans text-black">
        <DialogBackdrop className="fixed inset-0 bg-black opacity-50" />

        <DialogPanel className="inline-block w-full max-w-4xl my-4 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded">
          <DialogTitle
            as="h3"
            className="font-bold text-lg md:text-xl border-b border-[#D7DFE8] px-8 py-3 m-0"
          >
            {title} {account !== null && ` - ${account}`}
          </DialogTitle>
          <button
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
            onClick={onClose}
          >
            <i className="fa fa-times fa-lg"></i>
          </button>
          <div className="p-4">
            <HierarchySearch
              nodes={createHierarchyOptions(nodes, nodeNameMap)}
              onNodeSelect={handleNodeSelect}
            />

            {checkedNodeIds.length > 0 && (
              <div className="mt-2.5 flex gap-2.5">
                <div className="flex items-start flex-wrap gap-1.5">
                  {checkedNodeIds.map(nodeId => (
                    <TreeNodeTag
                      key={nodeId}
                      name={nodeNameMap[nodeId]?.tagName}
                      onRemove={() => updateNode(nodeId, false)}
                    />
                  ))}
                </div>

                <div className="shrink-0 ml-auto">
                  <TextButton size="small" onClick={clearAll}>
                    {i18n.ft(messages.clearAll)}
                  </TextButton>
                </div>
              </div>
            )}
          </div>

          <div>
            {nodes.map((node: AccountHierarchy) => (
              <TreeNode
                key={node.id}
                node={node}
                level={1}
                checkedMap={nodeMap}
                handleCheckboxChange={updateNode}
                openNodes={openNodes}
                onOpenNode={handleOpenNode}
                highlightedNodeId={highlightedNodeId}
              />
            ))}
          </div>
          <div className="flex justify-end px-4 py-4 border-t border-[#D7DFE8]">
            <button
              className="px-4 py-2 bg-action font-semibold text-white rounded"
              onClick={() => onSave(checkedNodeIds)}
            >
              {buttonText}
            </button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
