import i18n from 'helpers/i18n';
import messages from './messages';

export const ClassIcon = () => (
  <svg
    width="110"
    height="109"
    viewBox="0 0 110 109"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M79.1996 68.022C83.2745 55.4808 87.2374 42.9008 91.4918 30.4205C92.802 26.5796 90.7513 24.3449 87.0509 24.2674C69.0827 23.9 51.1178 23.4072 33.1526 22.954C25.1022 22.7515 17.0517 22.5267 9.00202 22.322C6.08019 22.2473 4.97449 23.2359 4.77698 26.1202C4.00211 37.5185 3.2415 48.919 2.47186 60.3166C1.96727 67.779 1.46042 75.2406 0.948337 82.7031C0.758082 85.4807 1.0989 86.2406 3.68403 87.2366C8.75662 89.1921 13.8818 91.0085 18.9837 92.8892C32.6457 97.9179 46.3032 102.953 59.9659 107.979C65.2915 109.937 65.8865 109.264 67.4421 104.331C71.2654 92.1957 75.2689 80.1194 79.2003 68.0198L79.1996 68.022Z"
      fill="#0A9CCA"
    />
    <path
      d="M44.2872 33.6983C44.8144 23.6388 45.432 13.5823 45.8146 3.51707C45.9316 0.419205 48.0059 -0.613106 50.6661 0.34467C63.581 5.00155 76.5278 9.56818 89.4641 14.1631C95.2608 16.2226 101.064 18.2661 106.86 20.3238C108.965 21.0704 109.483 22.0781 108.834 24.1892C106.266 32.5302 103.688 40.8689 101.116 49.2079C99.4332 54.668 97.7519 60.1282 96.0742 65.5903C95.4495 67.6233 94.9984 68.0719 92.8821 68.0746C88.7292 68.0804 84.5768 67.9728 80.4235 67.9173C69.3036 67.7636 58.1852 67.6153 47.0654 67.4597C42.7312 67.3984 42.4914 66.755 42.7325 62.811C43.3278 53.1102 43.7787 43.4018 44.2873 33.6965L44.2872 33.6983Z"
      fill="#7AA03F"
    />
    <path
      d="M44.7415 25.3159C44.588 28.11 44.4346 30.904 44.2882 33.6983L44.2883 33.6965C44.1429 36.4695 44.0023 39.2427 43.8617 42.0159C43.5102 48.9489 43.1587 55.8819 42.7335 62.811C42.4923 66.755 42.7322 67.3984 47.0664 67.4598C53.4115 67.5485 59.7562 67.6349 66.1009 67.7213C70.4803 67.7809 74.8598 67.8406 79.2395 67.901C80.3581 64.4576 81.4684 61.0113 82.5786 57.565C85.4992 48.4997 88.4198 39.4341 91.4923 30.4205C92.8026 26.5797 90.7519 24.345 87.0515 24.2675C73.3905 23.9881 59.7314 23.6363 46.0726 23.2845C45.6666 23.2741 45.2606 23.2636 44.8546 23.2532C44.817 23.9408 44.7792 24.6283 44.7415 25.3159Z"
      fill="white"
    />
  </svg>
);

export const filterOptions = [
  { value: null, text: i18n.ft(messages.allTypes) },
  { value: 'training', text: i18n.ft(messages.facilitatedTraining) },
  { value: 'course', text: i18n.ft(messages.onlineCoursework) }
];

export const sortOptions = [
  { value: 'desc', text: i18n.ft(messages.mostRecent) },
  { value: 'asc', text: i18n.ft(messages.oldest) },
  { value: 'a-z', text: 'A - Z' },
  { value: 'z-a', text: 'Z - A' }
];
