const PATH = 'fe.components.my_educators';

const messages = {
  name: {
    key: `${PATH}.name`,
    defaultValue: 'Name'
  },
  total: {
    key: `${PATH}.total`,
    defaultValue: 'Total Recommended Resources'
  },
  completed: {
    key: `${PATH}.completed`,
    defaultValue: 'Completed Resources'
  },
  educators: {
    key: `${PATH}.educators`,
    defaultValue: 'My Educators'
  },
  view: {
    key: `${PATH}.view`,
    defaultValue: 'View'
  },
  educatorView: {
    key: `${PATH}.educator_view`,
    defaultValue: 'You are in the educator view mode'
  },
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back'
  },
  searchPlaceholder: {
    key: `${PATH}.search_placeholder`,
    defaultValue: 'Search All Resources'
  },
  totalMobile: {
    key: `${PATH}.total_mobile`,
    defaultValue: 'Total'
  },
  completedMobile: {
    key: `${PATH}.completed_mobile`,
    defaultValue: 'Resources'
  }
};

export default messages;
