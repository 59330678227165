import { clsx } from 'clsx';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Button size. Defaults to `normal`. */
  size?: 'small' | 'normal';
}

export function TextButton({
  className,
  size = 'normal',
  ...props
}: ButtonProps) {
  const sizes = {
    small: 'text-sm py-1.5',
    normal: 'text-base py-2'
  };

  const classes = clsx(
    'font-sans font-semibold text-action underline',
    // hover state
    'hover:text-action hover:no-underline',
    // focus state
    'focus:border focus:border-action focus:rounded focus:no-underline',
    // size classes,
    sizes[size],
    // additional classes
    className
  );

  return <button className={classes} {...props} />;
}
