import i18n from 'helpers/i18n';
import messages from './messages';
import queryString from 'query-string';
import { useEffect, useState, Fragment, useMemo } from 'react';
import useAsync from 'hooks/useAsync';
import { Tab, TabGroup, TabPanels, TabPanel } from '@headlessui/react';
import { Link, Navigate } from 'react-router-dom';
import RoutesHelper from 'helpers/routes';
import PageWrapper from 'components/PageWrapper';
import MainContent from 'components/MainContent';
import { getAccountById } from 'actions/reports';
import useCurrentUser from 'hooks/useCurrentUser';
import AccountManager from 'components/Organization/AccountManager';
import { getCurrentAccount } from 'components/Organization/AccountManager/utils';
import { ENV_SCALES_URL } from 'constants/routes';
import { getAccountAssessmentTemplates } from 'actions/envScales/accountAssessmentTemplates';
import { AssessmentTemplate } from 'types/api/envScales/AssessmentTemplate';
import { clsx } from 'clsx';
import { AccountHierarchy } from 'types/api/AccountHierarchy';

import {
  ACCOUNT_AREAS,
  useGetAccountsByArea
} from 'actions/accountManager/accounts';

import {
  buildHierarchy,
  Account,
  formatParams,
  populateAgeLevelForAccount,
  getTemplateIdByAgeLevelAndClassVersion,
  useLastReportConfiguration
} from './utils';

import { CompareConfigReports } from 'components/Reports/Observation/CompareConfigReports';
import { SingleConfigReports } from 'components/Reports/Observation/SingleConfigReports';

import { Button, Dropdown, DropdownItemProps, Icon } from 'semantic-ui-react';

import {
  NoSupportText,
  GraphSection,
  TabButton,
  TabList,
  NoAccountText
} from './Styled';

import { ConfigurationCard } from 'components/Reports/Observation/ConfigurationCard';

const AREA = 'ObservationReports';

export function useAgeLevelOption(accountGuid?: string) {
  const defaultOption: DropdownItemProps = { value: '' };
  const defaultOptions: DropdownItemProps[] = [];
  const defaultTemplates: AssessmentTemplate[] = [];
  const [ageLevelOption, setAgeLevelOption] = useState(defaultOption);
  const [ageLevelOptions, setAgeLevelOptions] = useState(defaultOptions);
  const [accountTemplates, setAccountTemplates] = useState(defaultTemplates);

  useEffect(() => {
    async function fetchAccountTemplates() {
      if (!accountGuid) {
        return;
      }

      const { data } = await getAccountAssessmentTemplates(accountGuid);

      const templates = data.account_assessment_templates.map(
        ast => ast.assessment_template
      );

      const options = populateAgeLevelForAccount(templates);

      if (options.length > 0) {
        setAgeLevelOptions(options);
        setAgeLevelOption(options[0]);
        setAccountTemplates(templates);
      }
    }

    fetchAccountTemplates();
  }, [accountGuid]);

  return {
    ageLevelOption,
    ageLevelOptions,
    accountTemplates,
    setAgeLevelOption
  };
}

export function useCurrentAccountByArea(area: ACCOUNT_AREAS) {
  const { data: accounts = [] } = useGetAccountsByArea(area);
  const currentUser = useCurrentUser();

  const currentAccountId = getCurrentAccount(
    currentUser.current_account_id,
    accounts
  );

  return accounts.find(a => a.id === currentAccountId);
}

export function useAccountHierarchy(
  accountId?: number,
  initialChecked: number[] = []
) {
  const { run, data, isSuccess } = useAsync();

  useEffect(() => {
    if (!accountId) {
      return;
    }

    run(getAccountById(accountId));
  }, [accountId, run]);

  const hierarchy = useMemo(() => {
    return isSuccess ? buildHierarchy([data.hierarchy], initialChecked) : [];
    // Ignoring `initialChecked` from dependency array.
    // eslint-disable-next-line
  }, [isSuccess, data]);

  return hierarchy;
}

function ObservationsReport() {
  const { data: accounts = [], isPending: isPendingAccounts } =
    useGetAccountsByArea(AREA);

  const currentUser = useCurrentUser();
  const currentAccountId = getCurrentAccount(
    currentUser.current_account_id,
    accounts
  );

  const accountsFetched = accounts && accounts.length > 0;
  const account = accounts.find(a => a.id === currentAccountId);

  const { ageLevelOptions, accountTemplates } = useAgeLevelOption(
    account?.guid
  );

  const lastConfig = useLastReportConfiguration(currentAccountId);

  const startDate = lastConfig?.start_date;

  const endDate = lastConfig?.end_date;

  const nodeIds = lastConfig?.hierarchy_selections ?? [];

  const { run: accountRun, ...accountReq } = useAsync<Account>();

  const ageLevel = lastConfig?.age_level || '';

  const ageOption = ageLevelOptions.find(opt => opt.value === ageLevel);

  const assessmentTemplateId = getTemplateIdByAgeLevelAndClassVersion(
    ageOption,
    accountTemplates
  );

  const notAllowedToRequest = Boolean(
    !currentAccountId ||
      accounts?.length === 0 ||
      !accountTemplates ||
      !ageLevel ||
      !assessmentTemplateId ||
      !lastConfig
  );

  const notAllowToRequestAccount = !currentAccountId || accounts?.length === 0;

  useEffect(() => {
    if (notAllowToRequestAccount) {
      return;
    }

    accountRun(getAccountById(currentAccountId));
  }, [currentAccountId, accountRun, notAllowToRequestAccount]);

  function isNoDataToDisplay() {
    return !currentAccountId || !lastConfig;
  }

  function getNoDataMessage() {
    if (!currentAccountId) {
      return i18n.ft(messages.mustSelectAccount);
    }

    if (!lastConfig) {
      return i18n.ft(messages.mustHaveConfiguration);
    }
  }

  function allClassroom() {
    let nodeClassrooms: { [id: number]: boolean } = {};
    for (const node of lastConfig?.hierarchy_selections ?? []) {
      const foundNode =
        accountReq.data && findNodeById(accountReq.data.hierarchy, node);
      if (foundNode) {
        if (foundNode.type === 'Classroom') {
          nodeClassrooms[foundNode.id] = true;
        } else {
          nodeClassrooms[foundNode.id] = false;
        }
      }
    }
    const allClassrooms = Object.values(nodeClassrooms).every(
      value => value === true
    );
    return allClassrooms;
  }

  const findNodeById = (
    nodes: AccountHierarchy[],
    targetId: number
  ): AccountHierarchy | null => {
    for (const node of nodes) {
      if (node.id === targetId) {
        return node;
      }

      if (node.children && node.children.length > 0) {
        const foundNode = findNodeById(node.children, targetId);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  };

  function getProgramReportUrl() {
    const baseUrl = `${ENV_SCALES_URL}/api/v1/reports/observations/program.pdf`;
    const pEndDate = typeof endDate === 'string' ? new Date(endDate) : endDate;
    const pStartDate =
      typeof startDate === 'string' ? new Date(startDate) : startDate;

    const params = formatParams({
      ageLevel,
      startDate: pStartDate,
      endDate: pEndDate,
      nodeIds,
      assessmentTemplateId
    });

    return queryString.stringifyUrl(
      {
        url: baseUrl,
        query: params
      },
      { arrayFormat: 'bracket' }
    );
  }

  if (!isPendingAccounts && (!accounts || accounts.length === 0)) {
    return <Navigate replace to="/" />;
  }

  const configAProps = {
    ageLevel,
    startDate: typeof startDate === 'string' ? new Date(startDate) : startDate,
    endDate: typeof endDate === 'string' ? new Date(endDate) : endDate,
    nodeIds,
    assessmentTemplateId,
    isAllClassrooms: allClassroom(),
    reportConfigurationId: lastConfig?.id
  };

  let configBProps = null;
  if (lastConfig && lastConfig.comparison_config) {
    configBProps = {
      ageLevel: lastConfig.comparison_config.age_level,
      startDate: new Date(lastConfig.comparison_config.start_date),
      endDate: new Date(lastConfig.comparison_config.end_date),
      nodeIds: lastConfig.comparison_config.hierarchy_selections,
      assessmentTemplateId,
      reportConfigurationId: lastConfig.comparison_config.id
    };
  }

  const isProgramReportEnabled = Boolean(
    lastConfig && !lastConfig.comparison_config
  );

  return (
    <>
      <AccountManager
        area={AREA}
        accounts={accounts}
        currentAccountId={currentAccountId}
        isLoadingAccounts={isPendingAccounts}
      />
      {accountsFetched && (
        <>
          <div className="pt-10 pb-8 bg-white">
            <div className="max-w-7xl mx-auto px-[72px] flex justify-between">
              <h1 className="text-4xl font-bold">{i18n.ft(messages.header)}</h1>

              <div className="flex gap-6">
                <div className="hidden lg:block">
                  {isProgramReportEnabled && (
                    <Dropdown
                      fluid
                      button
                      floating
                      className={'blue'}
                      pointing="top right"
                      text={i18n.ft(messages.download)}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          as="a"
                          download
                          href={getProgramReportUrl()}
                        >
                          <div className="mb-2 font-bold">
                            {i18n.ft(messages.pdf)}
                          </div>
                          <span>{i18n.ft(messages.programReport)}</span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>

                <Button
                  inverted
                  color="blue"
                  as={Link}
                  to={RoutesHelper.getPath('reports-observation-new')}
                >
                  <Icon name="plus" />
                  <span>{i18n.ft(messages.createReport)}</span>
                </Button>

                {lastConfig ? (
                  <Button
                    inverted
                    color="blue"
                    as={Link}
                    to={RoutesHelper.getPath('reports-observation-edit', {
                      id: lastConfig.id
                    })}
                  >
                    <Icon name="pencil" />
                    <span>{i18n.ft(messages.editReport)}</span>
                  </Button>
                ) : null}
              </div>
            </div>

            <div className="max-w-7xl mx-auto px-[72px] mt-6">
              <p className="text-xl font-bold">
                {i18n.ft(messages.reportContains) + ':'}
              </p>

              {lastConfig &&
                (lastConfig.comparison_config ? (
                  <>
                    <ConfigurationCard config={lastConfig} cardNumber={1} />
                    <ConfigurationCard
                      config={lastConfig.comparison_config}
                      cardNumber={2}
                    />
                  </>
                ) : (
                  <ConfigurationCard config={lastConfig} />
                ))}
            </div>
          </div>
          <MainContent maxWidth={1280}>
            <PageWrapper>
              <div className="my-12 hidden lg:block">
                <GraphSection>
                  <TabGroup>
                    <TabList>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <TabButton
                            className={clsx('rounded-tl-xl', {
                              active: selected
                            })}
                          >
                            {i18n.ft(messages.domains)}
                          </TabButton>
                        )}
                      </Tab>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <TabButton
                            className={clsx('rounded-tr-xl', {
                              active: selected
                            })}
                          >
                            {i18n.ft(messages.dimensions)}
                          </TabButton>
                        )}
                      </Tab>
                    </TabList>

                    <TabPanels className="pb-6 mt-8">
                      <TabPanel unmount={false}>
                        {isNoDataToDisplay() ? (
                          <NoAccountText>
                            <Icon name="info circle" color="red" />
                            {getNoDataMessage()}
                          </NoAccountText>
                        ) : (
                          <>
                            {configBProps ? (
                              <CompareConfigReports
                                target="domain"
                                isFetchEnabled={!notAllowedToRequest}
                                configA={configAProps}
                                configB={configBProps}
                              />
                            ) : (
                              <SingleConfigReports
                                target="domain"
                                isFetchEnabled={!notAllowedToRequest}
                                {...configAProps}
                              />
                            )}
                          </>
                        )}
                      </TabPanel>

                      <TabPanel unmount={false}>
                        {isNoDataToDisplay() ? (
                          <NoAccountText>
                            <Icon name="info circle" color="red" />
                            {getNoDataMessage()}
                          </NoAccountText>
                        ) : (
                          <>
                            {configBProps ? (
                              <CompareConfigReports
                                target="dimension"
                                isFetchEnabled={!notAllowedToRequest}
                                configA={configAProps}
                                configB={configBProps}
                              />
                            ) : (
                              <SingleConfigReports
                                target="dimension"
                                isFetchEnabled={!notAllowedToRequest}
                                {...configAProps}
                              />
                            )}
                          </>
                        )}
                      </TabPanel>
                    </TabPanels>
                  </TabGroup>
                </GraphSection>
              </div>

              <NoSupportText className="lg:hidden">
                <p className="font-bold">{i18n.ft(messages.supportHeader)}</p>
                <p>{i18n.ft(messages.supportBody)}</p>
              </NoSupportText>
            </PageWrapper>
          </MainContent>
        </>
      )}
    </>
  );
}

export default ObservationsReport;
