import { clsx } from 'clsx';

interface PaginationButtonProps {
  /** Button content. */
  children: React.ReactNode;

  /** When `true`, active styles will be applied. */
  active?: boolean;

  /** When `true`, disabled styles will be applied. */
  disabled?: boolean;

  /** Callback fired when the button is clicked. */
  onClick?: () => void;
}

export function PaginationButton({
  active,
  children,
  ...props
}: PaginationButtonProps) {
  const classess = clsx(
    'font-sans font-semibold text-sm border rounded w-8 h-8',
    // default state
    !active && 'border-[#889EBB]',
    // hover state
    !active && 'hover:text-action hover:border-action',
    // focus state
    !active &&
      'focus:outline-none focus:border-2 focus:border-navy-300 focus:text-action',
    // active state
    'active:bg-action active:text-white active:border-action',
    // disabled state
    'disabled:bg-[#EBEFF4] disabled:cursor-not-allowed disabled:text-[#AFBFD2] disabled:pointer-events-none',
    // when active is `true`
    active &&
      'bg-action text-white border-action focus:outline-none focus:border-2 focus:border-action'
  );

  return (
    <button className={classess} {...props}>
      {children}
    </button>
  );
}
