import { createContext, useContext, useState, ReactNode } from 'react';

interface ClassroomProps {
  showAgeLevelDropdown: boolean;
  setShowAgeLevelDropdown: (value: boolean) => void;
}

const ClassroomContext = createContext<ClassroomProps | undefined>(undefined);

export const useClassroomContext = () => {
  const context = useContext(ClassroomContext);
  if (!context) {
    throw new Error(
      'useClassroomContext must be used inside ClassroomProvider'
    );
  }
  return context;
};

export const ClassroomProvider = ({ children }: { children: ReactNode }) => {
  const [showAgeLevelDropdown, setShowAgeLevelDropdown] =
    useState<boolean>(false);

  return (
    <ClassroomContext.Provider
      value={{ showAgeLevelDropdown, setShowAgeLevelDropdown }}
    >
      {children}
    </ClassroomContext.Provider>
  );
};
