const PATH = 'fe.pages.training_history';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Professional Development History'
  },
  timeframe: {
    key: `${PATH}.timeframe`,
    defaultValue: 'Select Timeframe:'
  },
  current: {
    key: `${PATH}.current`,
    defaultValue: 'Current School Year'
  },
  custom: {
    key: `${PATH}.custom`,
    defaultValue: 'Custom'
  }
};

export default messages;
