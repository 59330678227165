import { useState } from 'react';
import { NodeOption } from './HierarchySearch';
import { useGetHierarchy, useOrganizationAccount } from 'actions/accounts';
import { AccountHierarchy } from 'types/api/AccountHierarchy';

export type NodeMap = {
  [key: number]: { name: string; tagName: React.ReactNode };
};

export function generateNodeMap(nodes: AccountHierarchy[], parent?: string) {
  let nodeMap: NodeMap = {};

  for (const node of nodes) {
    const name = parent ? `${parent} / ${node.name}` : node.name;
    const tagName = parent ? (
      <>
        {parent} / <span className="font-semibold">{node.name}</span>
      </>
    ) : (
      <span className="font-semibold">{node.name}</span>
    );

    nodeMap[node.id] = { name, tagName };

    if (node.children.length > 0) {
      nodeMap = { ...nodeMap, ...generateNodeMap(node.children, name) };
    }
  }

  return nodeMap;
}

export function createHierarchyOptions(
  nodes: AccountHierarchy[],
  nodeMap: NodeMap,
  treeIds?: number[]
): NodeOption[] {
  let options: NodeOption[] = [];

  for (const node of nodes) {
    const name = nodeMap[node.id].name;
    const selfIds = treeIds ?? [];
    const childrenIds = [...selfIds, node.id];

    options.push({ id: node.id, name, treeIds: selfIds });

    if (node.children.length > 0) {
      options.push(
        ...createHierarchyOptions(node.children, nodeMap, childrenIds)
      );
    }
  }

  return options;
}

type NodeTreeLeaf = {
  id: number;
  isChecked: boolean;
  children: number[];
  parent: number | null;
};

export type NodeTreeMap = {
  [key: number]: NodeTreeLeaf;
};

export function useNodeCheckboxTree(
  nodes: AccountHierarchy[],
  initialCheckedIds: number[]
) {
  const [checkedNodeIds, setCheckedNodeIds] = useState(initialCheckedIds);
  const [nodeMap, setNodeMap] = useState<NodeTreeMap>(() => getInitialMap());

  function getInitialMap() {
    let initialMap = buildTreeMap(nodes, null);

    const allCheckedIds = initialCheckedIds
      .map(nodeId => getSelfAndDescendantIds(nodeId, initialMap))
      .flat();

    for (const nodeId of allCheckedIds) {
      if (initialMap[nodeId]) {
        initialMap[nodeId].isChecked = true;
      }
    }

    return initialMap;
  }

  function buildTreeMap(nodes: AccountHierarchy[], parent: number | null) {
    let treeMap: NodeTreeMap = {};

    for (const node of nodes) {
      const childrenIds = node.children.map(child => child.id);

      treeMap[node.id] = {
        id: node.id,
        isChecked: false,
        children: childrenIds,
        parent: parent
      };

      treeMap = {
        ...treeMap,
        ...buildTreeMap(node.children, node.id)
      };
    }

    return treeMap;
  }

  function clearAll() {
    const newMap = { ...nodeMap };

    for (const nodeId in newMap) {
      newMap[nodeId].isChecked = false;
    }

    setNodeMap(newMap);
    setCheckedNodeIds([]);
  }

  function updateNode(nodeId: number, isChecked: boolean) {
    const newMap = { ...nodeMap };
    const nodeIds = getSelfAndDescendantIds(nodeId, newMap);

    if (newMap[nodeId]) {
      // update all children and self to new value
      newMap[nodeId].isChecked = isChecked;
      for (const nodeId of nodeIds) {
        newMap[nodeId].isChecked = isChecked;
      }

      // update all parents based on their children values
      let parentId = newMap[nodeId].parent;
      while (parentId) {
        const parent = newMap[parentId];
        if (parent.children.some(childId => !newMap[childId].isChecked)) {
          parent.isChecked = false;
        }

        parentId = parent.parent;
      }
    }

    setNodeMap(newMap);
    setCheckedNodeIds(getCheckedIds(nodes, newMap));
  }

  function getSelfAndDescendantIds(nodeId: number, treeMap: NodeTreeMap) {
    const nodeIds = [nodeId];
    if (treeMap[nodeId] === undefined) {
      return nodeIds;
    }

    for (const childId of treeMap[nodeId].children) {
      nodeIds.push(...getSelfAndDescendantIds(childId, treeMap));
    }

    return nodeIds;
  }

  function getCheckedIds(nodes: AccountHierarchy[], checkedMap: NodeTreeMap) {
    const checkedIds: number[] = [];

    for (const node of nodes) {
      const isNodeChecked = checkedMap[node.id].isChecked;

      if (isNodeChecked) {
        checkedIds.push(node.id);
      } else {
        checkedIds.push(...getCheckedIds(node.children, checkedMap));
      }
    }

    return checkedIds;
  }

  return {
    nodeMap,
    updateNode,
    checkedNodeIds,
    clearAll
  };
}

export type HierarchyBuilder = {
  /** Account name. */
  account: string;

  /** Map of node names. */
  nodeNameMap: NodeMap;

  /** List of hierarchy nodes. */
  nodes: AccountHierarchy[];
};

export function useAccountHierarchy(accountId: number): HierarchyBuilder {
  const { data } = useGetHierarchy(accountId);
  const { data: accountData } = useOrganizationAccount(accountId);
  const nullHierarchy = [{ type: 'Account', name: '', children: [] }];
  const hierarchy = data?.hierarchy ?? nullHierarchy;
  let account = accountData?.name || nullHierarchy[0].name;
  let nodes = hierarchy;

  if (hierarchy.length > 0 && hierarchy[0].type === 'Account') {
    nodes = hierarchy[0].children;
  }

  return { account, nodes, nodeNameMap: generateNodeMap(nodes) };
}
