const PATH = 'fe.components.learning_resources.playlist_item';

const messages = {
  resources: {
    key: `${PATH}.resources`,
    defaultValue: 'Resources'
  },
  playlist: {
    key: `${PATH}.playlist`,
    defaultValue: 'Playlist'
  },
  complete: {
    key: `${PATH}.complete`,
    defaultValue: 'complete'
  },
  recommend: {
    key: `${PATH}.recommend`,
    defaultValue: 'Recommend'
  }
};

export default messages;
