import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect } from 'react';
import RouteHelpers from 'helpers/routes';
import useAsync from 'hooks/useAsync';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import PageLoader from 'components/PageLoader';
import MainContent from 'components/MainContent';
import BackToTop from 'components/Measure/BackToTop';
import InfoBox from 'components/Measure/View/InfoBox';
import PageHeader from 'components/Measure/PageHeader';
import PageBackground from 'components/PageBackground';
import { Assessment } from 'types/api/envScales/Assessment';
import ClassroomExamples from 'components/Measure/ClassroomExamples';
import ObservationScores from 'components/Measure/ObservationScores';
import { getSettings, GetSettingsRes } from 'actions/envScales/settings';
import AttachmentViewer from 'components/Measure/View/ExamplesAndNotes/Attachments/AttachmentViewer';
import { ENV_SCALES_URL } from 'constants/routes';
import ScoreSummary from 'components/Measure/View/ScoreSummary';

import {
  PageWrapper,
  Section,
  SectionActions,
  ActionWrapper
} from 'components/Measure/View/Styled';

function getPDFLink(id: number) {
  return `${ENV_SCALES_URL}/api/v1/assessments/${id}.pdf`;
}

function getCSVLink(assessment: Assessment) {
  const environment = assessment.assessment_template.env_scales
    ? '?includes_environments=true'
    : '';
  return `/api/v2/measurement/assessments/${assessment.id}.csv${environment}`;
}

interface CompletedCLASSProps {
  /** CLASS Assessment. */
  assessment: Assessment;

  /** User has observer role in the current account. */
  isObserver: boolean;

  /** User has admin role in the current account. */
  isAdmin: boolean;

  /** User has supervisor role in the current account. */
  isSupervisor: boolean;

  /** Assessment type is from class 2nd edition. */
  isClass2nd: boolean;
}

function CompletedCLASS({
  assessment,
  isObserver,
  isAdmin,
  isSupervisor,
  isClass2nd
}: CompletedCLASSProps) {
  const { run, data: settings, isPending } = useAsync<GetSettingsRes>();

  useEffect(() => {
    run(getSettings(assessment.account_guid));
  }, [run, assessment.account_guid]);

  if (isPending) {
    return <PageLoader />;
  }

  const {
    strength_and_growth_enabled: displayClassroomExamples,
    video_enabled: isVideoObservation
  } = settings.account_setting;

  return (
    <MainContent maxWidth={1280}>
      <PageWrapper>
        <PageHeader
          title={`${assessment.assessment_template.name} ${i18n.ft(
            messages.header
          )}`}
        />

        <PageBackground>
          <InfoBox
            data={assessment}
            attachToBottom
            extended
            isAdmin={isAdmin}
            isSupervisor={isSupervisor}
            isVideoObservation={isVideoObservation}
            requiresApproval={assessment.requires_approval}
          />

          {assessment.includes_environments && (
            <div className="mt-8 sm:mt-10">
              <ScoreSummary
                assessmentId={assessment.id}
                infoData={assessment}
                isEditable={false}
              />
            </div>
          )}

          <ObservationScores fitted={assessment.includes_environments} />
          {displayClassroomExamples && <ClassroomExamples />}
          <AttachmentViewer />

          <BackToTop />

          <Section mt="8px" borderBottomRadius="12px">
            <SectionActions>
              <ActionWrapper>
                <Button
                  as={Link}
                  to={RouteHelpers.getPath('measure')}
                  fluid
                  basic
                  icon="arrow alternate circle left outline"
                  content={i18n.ft(messages.returnToList)}
                />
              </ActionWrapper>
              {!isObserver &&
                assessment.primary_observer &&
                assessment.status === 'completed' && (
                  <>
                    {!isClass2nd && (
                      <ActionWrapper>
                        <Button
                          fluid
                          color="blue"
                          as="a"
                          href={getPDFLink(assessment.id)}
                          target="_blank"
                          content={i18n.ft(messages.downloadPDF)}
                        />
                      </ActionWrapper>
                    )}

                    <ActionWrapper>
                      <Button
                        fluid
                        color="blue"
                        as="a"
                        href={getCSVLink(assessment)}
                        target="_blank"
                        content={i18n.ft(messages.downloadCSV)}
                      />
                    </ActionWrapper>
                  </>
                )}
            </SectionActions>
          </Section>
        </PageBackground>
      </PageWrapper>
    </MainContent>
  );
}

export default CompletedCLASS;
