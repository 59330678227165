import { useGetAccountRoles } from 'actions/accounts';
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';

interface PopupProps {
  /** Account id */
  accountId: number;

  /** When `true`, the popup is open */
  isOpen: boolean;

  /** Callback fired when the popup closed */
  onClose: () => void;

  /** Callback fired when a role is selected*/
  onChange: (selectedValues: string[]) => void;

  /** List of Selected values */
  selectedValues: string[];
}

function RolePopup({
  accountId,
  isOpen,
  onClose,
  onChange,
  selectedValues
}: PopupProps) {
  const [internalValues, setInternalValues] =
    useState<string[]>(selectedValues);
  const popupRef = useRef<HTMLDivElement>(null);
  const { data } = useGetAccountRoles(accountId);

  const roles = useMemo(() => {
    if (!data) return [];
    return [...data.roles, { id: null, name: 'none_assigned' }];
  }, [data]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const updatedValues = checked
      ? [...internalValues, value]
      : internalValues.filter(v => v !== value);
    setInternalValues(updatedValues);
    onChange(updatedValues);
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    },
    [onClose]
  );

  const handleFocusOutside = useCallback(
    (event: FocusEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('focusin', handleFocusOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('focusin', handleFocusOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('focusin', handleFocusOutside);
    };
  }, [isOpen, handleClickOutside, handleFocusOutside]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      ref={popupRef}
      className="absolute mt-2 p-4 bg-white border rounded-lg shadow-lg z-50 mt-6 min-w-72 ring-2 ring-[#0A9CCA]"
      autoFocus
    >
      <h2 className="text-base font-bold mb-2">{i18n.ft(messages.seeOnly)}</h2>
      {roles.map(role => {
        const roleName =
          role.name === 'ca_admin'
            ? `${i18n.ft(messages.primaryAdmin)}`
            : role.name === 'none_assigned'
              ? `${i18n.ft(messages.none)}`
              : role.name.charAt(0).toUpperCase() +
                role.name.slice(1).replace(/_/g, ' ');
        const value = role.name === 'none_assigned' ? '' : role.id.toString();
        const isChecked = internalValues.includes(value);

        return (
          <label
            key={role.id}
            className={`block mb-1 p-2 rounded ${
              isChecked ? 'bg-action-tint' : ''
            }`}
          >
            <input
              type="checkbox"
              value={value}
              onChange={handleCheckboxChange}
              checked={isChecked}
              className="mr-2 accent-action h-4 w-4 border-action rounded text-action"
            />
            {roleName}
          </label>
        );
      })}
    </div>
  );
}

export default RolePopup;
