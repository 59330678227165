import DomainsChart from 'components/Reports/Observation/DomainsChart';
import { OptionalDate } from 'components/Reports/Observation/DateRangePicker';
import AveragesByNodeTable from 'components/Reports/Observation/AveragesByNodeTable';

import {
  getDomainColor,
  useGetReportAverages
} from 'pages/Reports/Observation/utils';
import AveragesByClassroomTable from '../AveragesByClassroomTable/AveragesByClassroomTable';

interface SingleConfigReportsProps {
  assessmentTemplateId: number;
  nodeIds: number[];
  ageLevel: string;
  startDate: OptionalDate;
  endDate: OptionalDate;
  target: 'domain' | 'dimension';
  isFetchEnabled: boolean;
  isAllClassrooms: boolean;
  reportConfigurationId?: number;
}

export function SingleConfigReports(props: SingleConfigReportsProps) {
  const graphReq = useGetReportAverages(props);
  const graphScores = graphReq.isSuccess ? graphReq.data.scores : [];

  const graphData = graphScores.map(score => ({
    name:
      props.target === 'domain'
        ? score.domain.abbreviation.toUpperCase()
        : score.dimension!.abbreviation.toUpperCase(),
    value: score.value,
    fill: getDomainColor(score.domain.t_name)
  }));

  return (
    <>
      <DomainsChart data={graphData} />
      {props.isAllClassrooms ? (
        <AveragesByClassroomTable target={props.target} props={props} />
      ) : (
        <AveragesByNodeTable target={props.target} props={props} />
      )}
    </>
  );
}
