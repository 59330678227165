import i18n from 'helpers/i18n';
import messages from './messages';
import { formatTime } from './utils';
import { LRSummary } from 'types/api/TrainingHistory';
import { useGetTrainingHistorySummary } from 'actions/trainingHistory';

interface LearningResourcesViewProps {
  summary: LRSummary;
}

function LearningResourcesView({ summary }: LearningResourcesViewProps) {
  const completedPercentage = Math.round(
    (summary.recommended_by_coach.total_completed /
      summary.recommended_by_coach.total_recommended) *
      100
  );

  return (
    <section>
      <h2 className="font-sans font-bold text-white text-2xl bg-lime-500 px-6 py-3 rounded-t-xl">
        {i18n.ft(messages.learningResources)}
      </h2>

      <div className="mt-6 p-6 pb-8 bg-white rounded-lg shadow-sm">
        <div className="grid grid-cols-[_1fr_auto_1fr]">
          <div className="grid grid-cols-[_3fr_1fr] gap-y-2">
            <div className="font-bold text-lg">
              {i18n.ft(messages.totalTime)}
            </div>
            <div className="font-bold text-lg">
              {formatTime(summary.time_spent.total_time_spent)}
            </div>
            <div className="mt-3">{i18n.ft(messages.fromCoach)}</div>
            <div className="mt-3">
              {formatTime(summary.time_spent.recommendations_from_coach)}
            </div>
            <div>{i18n.ft(messages.fromScores)}</div>
            <div>
              {formatTime(summary.time_spent.recommendations_based_on_scores)}
            </div>
            <div>{i18n.ft(messages.selfGuided)}</div>
            <div>{formatTime(summary.time_spent.self_guided_exploration)}</div>
          </div>

          <div className="mx-6 border-l border-navy-300" />

          <div className="grid grid-cols-[_3fr_1fr] gap-y-2">
            <div className="font-bold text-lg">
              {i18n.ft(messages.totalCompleted)}
            </div>
            <div className="font-bold text-lg">
              {i18n.ft(messages.resourcesCount, {
                count: summary.completed_resources.total_completed
              })}
            </div>
            <div className="mt-3">{i18n.ft(messages.fromCoach)}</div>
            <div className="mt-3">
              {i18n.ft(messages.resourcesCount, {
                count: summary.completed_resources.recommendations_from_coach
              })}
            </div>
            <div>{i18n.ft(messages.fromScores)}</div>
            <div>
              {i18n.ft(messages.resourcesCount, {
                count:
                  summary.completed_resources.recommendations_based_on_scores
              })}
            </div>
            <div>{i18n.ft(messages.selfGuided)}</div>
            <div>
              {i18n.ft(messages.resourcesCount, {
                count: summary.completed_resources.self_guided_exploration
              })}
            </div>
          </div>
        </div>

        <hr className="my-6 border-navy-300" />

        <div>
          <div className="font-bold text-lg">
            {i18n.ft(messages.recommendedByCoach)}
          </div>

          {summary.recommended_by_coach.total_recommended > 0 ? (
            <>
              <div className="mt-4 flex items-center justify-between">
                <span>
                  {i18n.ft(messages.recommendedAndCompleted, {
                    recommended: summary.recommended_by_coach.total_recommended,
                    completed: summary.recommended_by_coach.total_completed
                  })}
                </span>
                <span className="font-semibold">
                  {i18n.ft(messages.completed, {
                    percentage: completedPercentage
                  })}
                </span>
              </div>
              <div className="mt-2 h-4 bg-charcoal-100 border border-lime-500 rounded-full">
                <div
                  style={{ height: 14, width: `${completedPercentage}%` }}
                  className="bg-lime-500 rounded-full"
                />
              </div>
            </>
          ) : (
            <div className="mt-4">{i18n.ft(messages.noRecommendations)}</div>
          )}
        </div>
      </div>
    </section>
  );
}

interface LearningResourcesProps {
  startDate: Date | null;
  endDate: Date | null;
}

export function LearningResources({
  startDate,
  endDate
}: LearningResourcesProps) {
  const params = {
    start_date: startDate?.toISOString(),
    end_date: endDate?.toISOString()
  };

  const { data, isSuccess } = useGetTrainingHistorySummary(params);

  if (!isSuccess || !data.learning_resources) {
    return null;
  }

  return <LearningResourcesView summary={data.learning_resources} />;
}
