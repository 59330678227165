import i18n from 'helpers/i18n';
import messages from './messages';
import { PDSummary } from 'types/api/TrainingHistory';
import { CollapsibleSummary } from './CollapsibleSummary';
import { useGetTrainingHistorySummary } from 'actions/trainingHistory';

interface TrainingSummaryViewProps {
  summary: PDSummary;
}

function TrainingSummaryView({ summary }: TrainingSummaryViewProps) {
  const ceusList = [
    {
      text: i18n.ft(messages.onlineCoursework),
      value: summary.ceus_earned.courses
    },
    {
      text: i18n.ft(messages.facilitatedTrainings),
      value: summary.ceus_earned.trainings
    }
  ];

  const hoursList = [
    {
      text: i18n.ft(messages.onlineCoursework),
      value: summary.hours_earned.courses
    },
    {
      text: i18n.ft(messages.facilitatedTrainings),
      value: summary.hours_earned.trainings
    }
  ];

  if (typeof summary.hours_earned.learning_resources === 'number') {
    hoursList.push({
      text: i18n.ft(messages.learningResources),
      value: summary.hours_earned.learning_resources
    });
  }

  return (
    <section>
      <h2 className="font-sans font-bold text-white text-2xl bg-blue-500 px-6 py-3 rounded-t-xl">
        {i18n.ft(messages.pdAccomplishments)}
      </h2>

      <div className="mt-6 flex items-start gap-6">
        <div className="flex-1">
          <CollapsibleSummary
            label={i18n.ft(messages.ceus)}
            total={summary.ceus_earned.total_ceus}
            breakdownList={ceusList}
          />
        </div>

        <div className="flex-1">
          <CollapsibleSummary
            label={i18n.ft(messages.hours)}
            total={summary.hours_earned.total_hours}
            breakdownList={hoursList}
          />
        </div>
      </div>
    </section>
  );
}

interface TrainingSummaryProps {
  startDate: Date | null;
  endDate: Date | null;
}

export function TrainingSummary({ startDate, endDate }: TrainingSummaryProps) {
  const params = {
    start_date: startDate?.toISOString(),
    end_date: endDate?.toISOString()
  };

  const { data, isSuccess } = useGetTrainingHistorySummary(params);

  if (!isSuccess) {
    return null;
  }

  return <TrainingSummaryView summary={data.pd_accomplishments} />;
}
