import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import RouteHelpers from 'helpers/routes';
import { useEffect, useMemo, useState, useContext } from 'react';
import { getMyEducators } from 'actions/coachings';
import { Table } from 'components/Organization/Table';
import { useNavigate } from 'react-router-dom';
import arrowRightIcon from 'images/arrow_right.svg';
import ResourcesSection from 'components/LearningResources/ResourcesSection';
import useCurrentUser from 'hooks/useCurrentUser';
import { LearningResourcesContext } from 'pages/LearningResources/LearningResourcesContext';
import PageLoader from 'components/PageLoader';
import { useMediaQuery } from 'react-responsive';
import { hasLearningResourcesSubscription } from 'components/LearningResources/utils';

interface Educator {
  guid: string;
  full_name: string;
  recommendations_count: number;
  completed_recommendations_count: number;
}

function MyEducators() {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const isMobile = useMediaQuery({ minWidth: 640 });
  // Pagination
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortDir, setSortDir] = useState<'asc' | 'desc' | null>(null);
  const {
    run,
    data: { coachings: educators },
    isLoading
  } = useAsync({ data: {} });
  const learningResourcesContext = useContext(LearningResourcesContext);

  if (!learningResourcesContext) {
    throw new Error(
      'LearningResourcesHeader must be used within a Learning Resources Provider'
    );
  }

  const { refresh, setRefresh } = learningResourcesContext;

  // NOTE: Users that haven't an learning resource subscription
  //       should be redirected to the home page
  useEffect(() => {
    if (!hasLearningResourcesSubscription(currentUser)) {
      navigate(RouteHelpers.getPath('app'));
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    run(getMyEducators(), {
      onSuccess: () => {
        setRefresh(false);
      }
    });
  }, [run, refresh, setRefresh]);

  const tableHeaders = [
    {
      content: (
        <span className="text-black font-bold">{i18n.ft(messages.name)}</span>
      ),
      accessor: 'name',
      sortable: true
    },
    {
      content: (
        <span className="text-black font-bold">
          {isMobile ? i18n.ft(messages.total) : i18n.ft(messages.totalMobile)}
        </span>
      ),
      accessor: 'total'
    },
    {
      content: (
        <span className="text-black font-bold">
          {isMobile
            ? i18n.ft(messages.completed)
            : i18n.ft(messages.completedMobile)}
        </span>
      ),
      accessor: 'completed'
    },
    { content: '', accessor: 'view' }
  ];

  const tableData = useMemo(() => {
    if (!educators) {
      return [];
    }

    return educators.map((educator: Educator) => {
      return {
        name: educator.full_name,
        total: educator.recommendations_count,
        completed: educator.completed_recommendations_count,
        view: (
          <button
            className="font-bold text-[#47163F] text-base leading-6"
            onClick={() => {
              navigate(
                RouteHelpers.getPath('coach-learning-resources-recommend', {
                  guid: educator.guid
                })
              );
            }}
          >
            <div className="flex">
              <span>{`${i18n.ft(messages.view)} `}</span>
              {isMobile ? (
                <img
                  className={`inline ${isMobile ? 'ml-3' : 'ml-1'} `}
                  src={arrowRightIcon}
                  alt=""
                />
              ) : null}
            </div>
          </button>
        )
      };
    });
  }, [educators, isMobile, navigate]);

  function handleSort(header: string) {
    if (header === sortBy && sortDir === 'asc') {
      setSortDir('desc');
    } else if (header === sortBy && sortDir === 'desc') {
      setSortBy(null);
      setSortDir(null);
    } else {
      setSortBy(header);
      setSortDir('asc');
    }
  }

  return (
    <>
      {isLoading || !educators ? (
        <div className="pt-6">
          <PageLoader />
        </div>
      ) : (
        <>
          <div className="mt-10 pb-6 rounded-2xl bg-white">
            <div className="flex flex-col">
              <div>
                <h2 className="font-sans font-bold text-3xl text-black pl-2 py-4">
                  {i18n.ft(messages.educators)}
                </h2>
              </div>
            </div>

            <Table
              headers={tableHeaders}
              data={tableData}
              sortBy={sortBy}
              sortDir={sortDir}
              onSort={handleSort}
            />
          </div>
        </>
      )}

      <div className="mt-2 mb-16 px-4 md:px-0 md:py-8">
        <ResourcesSection action={'lastViewed'} />
        <ResourcesSection action={'important'} />
      </div>
    </>
  );
}

export default MyEducators;
