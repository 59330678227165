import { clsx } from 'clsx';
import { Checkbox } from '@headlessui/react';
import { AccountHierarchy } from 'types/api/AccountHierarchy';
import { NodeTreeMap } from './utils';

interface TreeNodeProps {
  /** Account Hierarchy Node. */
  node: AccountHierarchy;

  /** Depth level of the node. */
  level: number;

  /** Node Tree Map. */
  checkedMap: NodeTreeMap;

  /** Callback fired when clicking a node checkbox. */
  handleCheckboxChange: (id: number, isChecked: boolean) => void;

  /** Node ids for all open/expanded nodes. */
  openNodes: number[];

  /** Callback fired when expading/collaping a node. */
  onOpenNode: (nodeId: number) => void;

  /** Node id for the current highlighted node. */
  highlightedNodeId: number | null;
}

export function TreeNode({
  node,
  level,
  openNodes,
  onOpenNode,
  checkedMap,
  handleCheckboxChange,
  highlightedNodeId
}: TreeNodeProps) {
  const isOpen = openNodes.includes(node.id);

  const handleOpenNode = () => {
    onOpenNode(node.id);
  };

  const handleCheckboxClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const isHighlighted = highlightedNodeId === node.id;
  const isChecked = checkedMap[node.id].isChecked;

  const classes = clsx(
    'flex justify-between items-center py-2 border-t border-[#D7DFE8] cursor-pointer',
    isHighlighted && !isChecked && 'bg-[#F7F8F9]',
    isChecked && 'bg-action-tint'
  );

  return (
    <div data-hierarchy-id={node.id}>
      <div
        className={classes}
        style={{ paddingLeft: `${level * 32}px` }}
        onClick={handleOpenNode}
      >
        <Checkbox
          checked={isChecked}
          onChange={() => handleCheckboxChange(node.id, !isChecked)}
          onClick={handleCheckboxClick}
          className="relative mr-2 h-4 w-4 border border-action rounded"
        >
          <div
            className={`flex items-center justify-center h-full w-full pt-px ${isChecked ? 'bg-action' : ''}`}
          >
            {isChecked && (
              <i className="fa-duotone fa-check fa-sm text-white" />
            )}
          </div>
        </Checkbox>
        <div className="flex-grow">{node.name}</div>
        {node.children.length > 0 && (
          <button className="mr-4">
            {isOpen ? (
              <i className="fa-solid fa-caret-up" />
            ) : (
              <i className="fa-solid fa-caret-down" />
            )}
          </button>
        )}
      </div>
      {isOpen && (
        <>
          {node.children.map(child => (
            <TreeNode
              key={child.id}
              node={child}
              level={level + 1}
              checkedMap={checkedMap}
              handleCheckboxChange={handleCheckboxChange}
              openNodes={openNodes}
              onOpenNode={onOpenNode}
              highlightedNodeId={highlightedNodeId}
            />
          ))}
        </>
      )}
    </div>
  );
}
