import i18n from 'helpers/i18n';
import messages from './messages';

interface TreeNodeTagProps {
  /** Tree node name with hierarchy. */
  name: React.ReactNode;

  /** Callback fired when the remove button is clicked. */
  onRemove: () => void;
}

export function TreeNodeTag({ name, onRemove }: TreeNodeTagProps) {
  const handleRemove = (event: React.MouseEvent) => {
    event.stopPropagation();
    onRemove();
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.stopPropagation();
      onRemove();
    }
  };

  function tagName(name: React.ReactNode) {
    if (name === undefined) {
      return <span>{i18n.ft(messages.invalidTagName)}</span>;
    }
    return name;
  }

  return (
    <div
      tabIndex={-1}
      className="flex items-center gap-2 pl-1.5 pr-1 py-0.5 bg-action-tint rounded border border-[#889EBB]"
    >
      <span className="text-sm">{tagName(name)}</span>

      <button
        type="button"
        onClick={handleRemove}
        onKeyDown={handleKeyDown}
        className="w-5 h-5 flex justify-center items-center"
      >
        <i className="fa-solid fa-times" />
      </button>
    </div>
  );
}
