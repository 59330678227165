const PATH = 'fe.components.training_history.training_list';

const messages = {
  sortBy: {
    key: `${PATH}.sort_by`,
    defaultValue: 'Sort By'
  },
  trainingCourses: {
    key: `${PATH}.training_courses`,
    defaultValue: 'Trainings & Courses'
  },
  mostRecent: {
    key: `${PATH}.most_recent`,
    defaultValue: 'Most Recent'
  },
  oldest: {
    key: `${PATH}.oldest`,
    defaultValue: 'Oldest'
  },
  facilitatedTraining: {
    key: `${PATH}.facilitated_training`,
    defaultValue: 'Facilitated Training'
  },
  onlineCoursework: {
    key: `${PATH}.online_coursework`,
    defaultValue: 'Online Coursework'
  },
  allTypes: {
    key: `${PATH}.all_types`,
    defaultValue: 'All Types'
  },
  searchPlaceholder: {
    key: `${PATH}.search_placeholder`,
    defaultValue: 'Search keyword or name'
  },
  search: {
    key: `${PATH}.search`,
    defaultValue: 'Search'
  },
  filterByType: {
    key: `${PATH}.filter_by_type`,
    defaultValue: 'Filter by Type'
  },
  noTrainingsTitle: {
    key: `${PATH}.no_trainings_title`,
    defaultValue:
      'You haven’t completed any trainings or courses during the timeframe selected.'
  },
  noTrainingsEverTitle: {
    key: `${PATH}.no_trainings_ever_title`,
    defaultValue: "You haven't completed any trainings or courses yet."
  },
  noTrainingsContent: {
    key: `${PATH}.no_trainings_content`,
    defaultValue:
      'When you have completed a facilitated training or online course, you will see them listed here, along with your certificates of completion.'
  }
};

export default messages;
