const PATH = 'fe.pages.learning_resources.playlist';

const messages = {
  time: {
    key: `${PATH}.time`,
    defaultValue: 'Estimated Time:'
  },
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back'
  }
};

export default messages;
