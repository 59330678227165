import DetailPage from './DetailPage';
import { Route, Routes } from 'react-router-dom';
import PlaylistDetailPage from 'pages/LearningResources/Playlist/PlaylistDetailPage';
import PreferencesPage from 'pages/LearningResources/PreferencesPage';
import LearningResourcesProvider from './LearningResourcesContext';
import EducatorHeader from 'components/LearningResources/EducatorHeader';
import EducatorHomePage from './EducatorHomePage';

function LearningResources() {
  return (
    <LearningResourcesProvider>
      <Routes>
        <Route element={<EducatorHeader />}>
          <Route path=":id" element={<DetailPage />} />
          <Route path="playlist/:id" element={<PlaylistDetailPage />} />
          <Route path="preferences" element={<PreferencesPage />} />
          <Route index element={<EducatorHomePage />} />
        </Route>
      </Routes>
    </LearningResourcesProvider>
  );
}

export default LearningResources;
