import i18n from 'helpers/i18n';
import messages from './messages';
import { ENV_SCALES_URL } from 'constants/routes';
import { Assessment } from 'types/api/envScales/Assessment';

export function getTeacherNames(teachers: string[]) {
  if (teachers.length <= 2) {
    return { teachers: teachers.join(', '), popupTeachers: '' };
  }

  const [t1, t2, ...restTeachers] = teachers;

  return {
    teachers: `${t1}, ${t2} ${i18n.ft(messages.moreTeachers)}`,
    popupTeachers: restTeachers.join(', ')
  };
}

export function getPDFLink(assessmentId: number) {
  return `${ENV_SCALES_URL}/api/v1/assessments/${assessmentId}.pdf`;
}

export function getCSVLink(
  assessment: Assessment,
  environmentReport: boolean = false
) {
  const environment =
    assessment.assessment_template.env_scales || environmentReport
      ? '?includes_environments=true'
      : '';
  return `/api/v2/measurement/assessments/${assessment.id}.csv${environment}`;
}

export function getAssessmentType(template: {
  env_scales: boolean;
  class_version: number;
}) {
  if (template.env_scales) {
    return 'environments';
  }
  return `class${template.class_version}`;
}

export const pillColors = {
  environments: 'scaleRosa',
  class1: 'scaleLightBlue',
  class2: 'scaleLightBlue'
};

export function getAgeLevel(ageLevel: string) {
  if (ageLevel === 'PK-3rd') {
    return 'pk3rd';
  } else if (ageLevel === 'PK-K') {
    return 'pkk';
  } else {
    return ageLevel.toLowerCase();
  }
}

export const ageLevelPillsColor = {
  'PK-3rd': 'pre-k',
  'Pre-K': 'pre-k',
  'PK-K': 'pre-k',
  Inf: 'inf',
  Todd: 'todd'
};
