import { Input } from 'components/Organization/Input';
import { PrimaryButton } from 'components/Organization/PrimaryButton';

import i18n from 'helpers/i18n';
import messages from './messages';
import { TextButton } from 'components/Organization/TextButton';
import { useState } from 'react';

interface NodeFormProps {
  /**Depth level of the node */
  depth: number;

  /**Function to set the adding/editing state */
  setIsAvailable: (value: boolean) => void;

  /**Type of the form */
  type: 'edit' | 'add';

  /**Function to handle the save event */
  onSave: (event: React.FormEvent<HTMLFormElement>, value: any) => void;

  /** The initial value for the input */
  value?: string;
}

function NodeForm({
  depth,
  setIsAvailable,
  type,
  onSave,
  value = ''
}: NodeFormProps) {
  const [nodeName, setNodeName] = useState(value);

  function handleSaveNode(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    onSave(event, nodeName);
  }

  return (
    <form
      style={{ paddingLeft: type === 'add' ? (depth + 1) * 24 : depth * 24 }}
      className="py-2 pr-16 flex items-center gap-6 border-b border-[#D8D9D9] bg-action-tint"
      onSubmit={handleSaveNode}
    >
      <div className="flex items-center gap-3 flex-1">
        {depth > 1 && <i className="fa-solid fa-arrow-turn-down-right" />}
        <div className="flex-1">
          <Input
            name="name"
            value={nodeName}
            onChange={e => setNodeName(e.target.value)}
          />
        </div>
      </div>

      <PrimaryButton type="submit" size="small">
        {i18n.ft(messages.save)}
      </PrimaryButton>

      <TextButton
        type="button"
        size="small"
        onClick={() => setIsAvailable(false)}
      >
        {i18n.ft(messages.cancel)}
      </TextButton>
    </form>
  );
}

export default NodeForm;
