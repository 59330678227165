import { clsx } from 'clsx';
import i18n from 'helpers/i18n';
import messages from './messages';
import RoutesHelper from 'helpers/routes';
import { NavLink } from 'react-router-dom';
import RouteHelpers from 'helpers/routes';
import useCurrentUser from 'hooks/useCurrentUser';
import { useMediaQuery } from 'react-responsive';

function Header() {
  const { roles } = useCurrentUser();
  const isMobile = useMediaQuery({ minWidth: 640 });

  function isCoach() {
    return roles.includes('lr_coach');
  }

  function isCoachAndEducator() {
    return roles.includes('lr_coach') && roles.includes('lr_educator');
  }

  return (
    <>
      {isCoachAndEducator() && (
        <div className="w-full mt-4 p-4 bg-[#cbe3ed] rounded mb-2">
          <div className="flex justify-between">
            <div>
              <a
                href={RouteHelpers.getPath('learning-resources')}
                className="font-bold"
              >
                {`${i18n.ft(messages.educator)} >`}
              </a>
            </div>
          </div>
        </div>
      )}

      <h1 className="text-4xl text-black">{i18n.ft(messages.title)}</h1>

      <div className="mt-10 border-b-2 border-[#85878A]">
        <NavLink
          end
          to={`${
            isCoach()
              ? RoutesHelper.getPath('coach-learning-resources')
              : RoutesHelper.getPath('learning-resources')
          }`}
          className={({ isActive }) =>
            clsx('text-black hover:text-black', {
              'font-bold border-b-4 border-black': isActive
            })
          }
        >
          {i18n.ft(messages.home)}
        </NavLink>
        {isCoach() && (
          <NavLink
            end
            to={RoutesHelper.getPath('coach-learning-resources-recommend', {
              guid: ''
            })}
            className={({ isActive }) =>
              clsx('ml-14 text-black hover:text-black', {
                'font-bold border-b-4 border-black': isActive
              })
            }
          >
            {i18n.ft(messages.recommend)}
          </NavLink>
        )}
        <NavLink
          to={RoutesHelper.getPath('coach-learning-resources-see-all')}
          className={({ isActive }) =>
            clsx('ml-14 text-black hover:text-black', {
              'font-bold border-b-4 border-black': isActive
            })
          }
        >
          {isMobile
            ? i18n.ft(messages.browseResources)
            : i18n.ft(messages.resources)}
        </NavLink>
      </div>
    </>
  );
}

export default Header;
