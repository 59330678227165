import i18n from 'helpers/i18n';
import messages from './messages';
import StyledLoader from './Styled';
import { useEffect, useState } from 'react';
import { useRouteParams } from 'hooks/useRouteParams';
import { URLParams } from 'components/TestAttempts/utils';
import { setCurrentQuestion } from 'actions/knowledgeTests';
import { useKnowledgeTest } from '../KnowledgeTestContext';
import { KnowledgeTestQuestion } from 'types/api/KnowledgeTest';
import { focusQuestionDescription } from '../utils';

interface QuestionsSummaryProps {
  setQuestion: (question: KnowledgeTestQuestion) => void;
  setQuestionState: (questionState: {
    nextQuestionId: number;
    previousQuestionId: number;
  }) => void;
  setShowConfirmationModal: (showConfirmationModal: boolean) => void;
  setIsReadyToSubmit: (testCompleted: boolean) => void;
}

function QuestionsSummary({
  setQuestion,
  setQuestionState,
  setShowConfirmationModal,
  setIsReadyToSubmit
}: QuestionsSummaryProps) {
  const { id } = useRouteParams<URLParams>();
  const { knowledgeTest, isPending } = useKnowledgeTest();
  const [testCompleted, setTestCompleted] = useState<boolean>(false);

  useEffect(() => {
    if (!knowledgeTest) return;

    if (
      knowledgeTest.categories
        .map(c => c.questions)
        .flat()
        .every(q => q.answered === true)
    ) {
      setTestCompleted(true);
    }
  }, [knowledgeTest]);

  useEffect(() => {
    if (!testCompleted) {
      setIsReadyToSubmit(false);
    } else {
      setIsReadyToSubmit(true);
    }
  }, [setIsReadyToSubmit, testCompleted]);

  function nextQuestion(nextQuestionId: number) {
    setCurrentQuestion(parseInt(id), nextQuestionId).then(
      ({ data: nextQuestion }) => {
        setQuestion(nextQuestion.current_question);
        setQuestionState({
          nextQuestionId: nextQuestion.next_question_id,
          previousQuestionId: nextQuestion.previous_question_id
        });
        setShowConfirmationModal(false);
        // Accessibility user experience
        focusQuestionDescription();
      }
    );
  }

  if (isPending) {
    return <StyledLoader active size="large" inline="centered" />;
  }

  return (
    <div style={{ marginLeft: '-15px', marginRight: '-15px' }}>
      <div className="mx-3 font-semibold mb-5 md:mx-0">
        {testCompleted
          ? i18n.ft(messages.please)
          : i18n.ft(messages.instructions)}
      </div>
      <div className="grid grid-cols-2 gap-4 mb-5">
        <div className="inline-flex justify-center text-center">
          <div className="rounded-full w-4 h-4 bg-[#017EA7] self-center"></div>
          <div className="ml-3">{i18n.ft(messages.answered)}</div>
        </div>
        <div className="inline-flex justify-center text-center">
          <div className="rounded-full w-4 h-4 border border-orange-300 border-2 self-center"></div>
          <div className="ml-3">{i18n.ft(messages.unanswered)}</div>
        </div>
      </div>
      <div className="mx-3 font-semibold mb-5 md:mx-0">
        {i18n.ft(messages.click)}
      </div>
      {knowledgeTest?.categories.map((category, idx) => {
        return (
          <div className="bg-[#F7F8F9] px-2 md:px-5 py-3 my-3" key={idx}>
            <div className="font-semibold mb-5">{category.name}</div>
            <div className="grid grid-cols-3 md:grid-cols-11">
              {category.questions.map((question, index) => {
                return (
                  <div
                    className="contents cursor-pointer"
                    key={index}
                    onClick={() => nextQuestion(question.id)}
                  >
                    <div
                      className="inline-flex justify-center text-center"
                      tabIndex={0}
                      onKeyDown={e => {
                        if (e.key === 'Enter' || e.code === 'Space')
                          nextQuestion(question.id);
                      }}
                      aria-label={`Question ${question.sequence} ${
                        question.answered ? 'answered' : 'unanswered'
                      }`}
                    >
                      {question.answered ? (
                        <div className="rounded-full w-4 h-4 bg-[#017EA7] self-center mb-6"></div>
                      ) : (
                        <div className="rounded-full w-4 h-4 border-orange-300 border-2 self-center mb-6"></div>
                      )}
                      <div className="col-span-1 ml-4">{question.sequence}</div>
                    </div>

                    {index !== 5 && (
                      <div className="hidden md:block col-span-1"></div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default QuestionsSummary;
