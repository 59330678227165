import * as R from 'ramda';
import { getFullName } from 'helpers/user';
import { useEffect } from 'react';
import useCurrentUser from './useCurrentUser';
import { getMyCertifications } from 'actions/certifications';
import { useQuery } from '@tanstack/react-query';

const Pendo = (window as any).pendo;

function usePendo() {
  const currentUser = useCurrentUser();

  const { data } = useQuery({
    queryKey: ['users', 'me', 'certifications', currentUser?.id],
    queryFn: () => getMyCertifications().then(res => res.data),
    enabled: Boolean(currentUser?.id),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (R.isNil(currentUser.id) || R.isNil(currentUser.guid)) {
      return;
    }

    const certifications = data?.user_certifications ?? [];

    const observerCerts = certifications.filter(
      c => c.certification.group_name === 'Observer'
    );

    const metadata = {
      apiKey: 'dbfacce6-ec16-4ebd-6a84-17c7e1b6bf54',
      visitor: {
        id: currentUser.guid,
        userId: currentUser.id,
        email: currentUser.email,
        name: getFullName(currentUser),
        isObserver: observerCerts.some(c => !c.expired)
      }
    };

    if (Pendo.isReady?.()) {
      Pendo.updateOptions(metadata);
    } else {
      Pendo.initialize(metadata);
    }
  }, [currentUser, data]);
}

export default usePendo;
