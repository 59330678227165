import { clsx } from 'clsx';
import { useState } from 'react';
import { HierarchyBuilder } from './utils';
import { TreeNodeTag } from './TreeNodeTag';
import { Field, Label } from '@headlessui/react';
import { HierarchyModal } from './HierarchyModal';

interface HierarchyInputProps extends HierarchyBuilder {
  /** Selected node ids. */
  value: number[];

  /** Callback fired when the selected node ids changed. */
  onChange: (value: number[]) => void;

  /** Optional field label. */
  label?: string;

  /** When `true`, label indicates field is required. */
  required?: boolean;

  /** Optional text for placeholder content. */
  placeholder?: string;

  /** When `true`, the input is disabled. */
  disabled?: boolean;
}

export function HierarchyInput({
  value,
  onChange,
  label,
  required,
  placeholder,
  nodes,
  nodeNameMap,
  account,
  disabled
}: HierarchyInputProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const classes = clsx(
    'relative text-base text-[#3C3F42] border border-[#889EBB] rounded-lg p-2 pr-8',
    // focus state
    'focus:outline-0 focus:border-[#0A9CCA] focus:ring-1 focus:ring-[#0A9CCA]',
    // disabled state
    disabled && 'bg-navy-50 text-navy-200 cursor-not-allowed'
  );

  const handleInputClick = () => {
    if (disabled) {
      return;
    }

    setIsModalOpen(true);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (disabled) {
        return;
      }

      setIsModalOpen(true);
    }
  };

  const handleModalSave = (nodeIds: number[]) => {
    onChange(nodeIds);
    setIsModalOpen(false);
  };

  const handleTagRemove = (nodeId: number) => {
    onChange(value.filter(id => id !== nodeId));
  };

  return (
    <Field className="flex flex-col gap-1 font-sans">
      {label && (
        <Label className="font-semibold text-base text-black">
          {label + (required ? '*' : '')}
        </Label>
      )}

      <div
        tabIndex={0}
        className={classes}
        onClick={handleInputClick}
        onKeyDown={handleInputKeyDown}
      >
        {value?.length > 0 ? (
          <div className="flex flex-wrap gap-1.5">
            {value.map(nodeId => (
              <TreeNodeTag
                key={nodeId}
                name={nodeNameMap[nodeId]?.tagName}
                onRemove={() => handleTagRemove(nodeId)}
              />
            ))}
          </div>
        ) : (
          placeholder
        )}

        <div className="absolute right-0 inset-y-0 flex items-center pr-2.5">
          <i className="fa-solid fa-magnifying-glass" />
        </div>
      </div>

      {isModalOpen && (
        <HierarchyModal
          account={account}
          nodes={nodes}
          nodeNameMap={nodeNameMap}
          isOpen={isModalOpen}
          buttonText="Continue"
          title="Select Hierarchy Association"
          selectedNodes={value}
          onSave={handleModalSave}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </Field>
  );
}
