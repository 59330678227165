import * as R from 'ramda';
import CompareBarChart from 'components/Reports/Observation/CompareBarChart';
import { OptionalDate } from 'components/Reports/Observation/DateRangePicker';

import {
  getDomainColor,
  getDomainBgColor,
  useGetReportAverages
} from 'pages/Reports/Observation/utils';

interface CompareConfigReportsProps {
  isFetchEnabled: boolean;
  target: 'domain' | 'dimension';

  configA: {
    assessmentTemplateId: number;
    nodeIds: number[];
    ageLevel: string;
    startDate: OptionalDate;
    endDate: OptionalDate;
    reportConfigurationId?: number;
  };

  configB: {
    assessmentTemplateId: number;
    nodeIds: number[];
    ageLevel: string;
    startDate: OptionalDate;
    endDate: OptionalDate;
    reportConfigurationId?: number;
  };
}

export function CompareConfigReports({
  configA,
  configB,
  target,
  isFetchEnabled
}: CompareConfigReportsProps) {
  const graphAReq = useGetReportAverages({
    ...configA,
    target,
    isFetchEnabled
  });

  const graphBReq = useGetReportAverages({
    ...configB,
    target,
    isFetchEnabled
  });

  const graphAScores = graphAReq.isSuccess ? graphAReq.data.scores : [];
  const graphBScores = graphBReq.isSuccess ? graphBReq.data.scores : [];

  const graphData = graphAScores.map((score, idx) => ({
    name:
      target === 'domain'
        ? score.domain.abbreviation.toUpperCase()
        : score.dimension!.abbreviation.toUpperCase(),
    valueA: score.value,
    valueB: graphBScores[idx]?.value ?? 0,
    fill: getDomainColor(score.domain.t_name)
  }));

  const legendColors = R.uniq(
    graphAScores.map(score => getDomainBgColor(score.domain.t_name))
  );

  return <CompareBarChart legendColors={legendColors} data={graphData} />;
}
