import { useEffect } from 'react';
import { PageContainer } from './Styled';
import OnboardingManager from 'components/Onboarding';
import { updateUserMeSettings } from 'actions/userMe';

function Onboarding() {
  useEffect(() => {
    // adding delay to fix race condition between user/me endpoint.
    setTimeout(() => {
      updateUserMeSettings({
        user_setting: {
          onboarding_viewed_at: new Date().toUTCString()
        }
      });
    }, 1000);
  }, []);

  return (
    <PageContainer>
      <OnboardingManager />
    </PageContainer>
  );
}
export default Onboarding;
