import { MODE } from 'components/LearningResources/types';
import { ReactNode, createContext, useState } from 'react';

interface LearningResourcesProviderProps {
  children: ReactNode;
}

export type LearningResource = {
  name: string;
  id: number;
  videoURI?: string;
  estimatedTime?: string;
  type: string;
};

export const LearningResourcesContext = createContext<{
  recommendedVideosArray: LearningResource[];
  setRecommendedVideosArray: React.Dispatch<
    React.SetStateAction<LearningResource[]>
  >;
  selectedTeachersArray: any[];
  setSelectedTeachersArray: React.Dispatch<React.SetStateAction<any[]>>;
  isPending: boolean;
  setIsPending: React.Dispatch<React.SetStateAction<boolean>>;
  canSubmit: boolean;
  setCanSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  mode: MODE;
  setMode: React.Dispatch<React.SetStateAction<MODE>>;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  recommendedVideosArray: [],
  setRecommendedVideosArray: () => {},
  selectedTeachersArray: [],
  setSelectedTeachersArray: () => {},
  isPending: false,
  setIsPending: () => {},
  canSubmit: false,
  setCanSubmit: () => {},
  mode: 'no_duplication',
  setMode: () => {},
  refresh: false,
  setRefresh: () => {}
});

export const LearningResourcesProvider = ({
  children
}: LearningResourcesProviderProps) => {
  const [recommendedVideosArray, setRecommendedVideosArray] = useState<
    LearningResource[]
  >([]);
  const [selectedTeachersArray, setSelectedTeachersArray] = useState<any[]>([]);
  const [isPending, setIsPending] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [mode, setMode] = useState<MODE>('no_duplication');
  const [refresh, setRefresh] = useState(false);

  return (
    <LearningResourcesContext.Provider
      value={{
        recommendedVideosArray,
        setRecommendedVideosArray,
        selectedTeachersArray,
        setSelectedTeachersArray,
        isPending,
        setIsPending,
        canSubmit,
        setCanSubmit,
        mode,
        setMode,
        refresh,
        setRefresh
      }}
    >
      {children}
    </LearningResourcesContext.Provider>
  );
};

export default LearningResourcesProvider;
