import { useState, useEffect } from 'react';
import { PageContainer } from 'pages/Users/Login/Styled';
import ProductKeyForm from 'components/Users/ActivateProductKey/Form/ProductKeyForm';
import PhonePrompt from 'components/Users/PhonePrompt';
import ContainerWithLogo from 'components/ContainerWithLogo';
import useCurrentUser from 'hooks/useCurrentUser';
import { useNavigate } from 'react-router-dom';
import { PostActivationData } from 'components/ActivateProductKey/utils';
import { fetchUserMe } from 'actions/userMe';
import { useDispatch } from 'react-redux';
import { update } from 'store/slices/user';

function ActivateProductKey() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const [step, setStep] = useState('login');
  const [activationData, setActivationData] = useState<PostActivationData>();

  useEffect(() => {
    if (currentUser.id && !Boolean(activationData)) {
      navigate('/', { replace: true });
    }
  }, [currentUser, navigate, activationData]);

  function handleLogin(data: PostActivationData) {
    setActivationData(data);
    setStep('enterPhone');
  }

  async function handlePhoneSuccess() {
    const { source, path, state, skipOnboarding } = activationData!;
    if (source === 'external') {
      window.location.href = path;
    } else {
      const response = await fetchUserMe();
      dispatch(update({ ...response.data, skipOnboarding }));
      navigate(path, { state });
    }
  }

  function renderForm() {
    switch (step) {
      case 'login':
        return <ProductKeyForm onSuccess={handleLogin} />;
      case 'enterPhone':
        return <PhonePrompt onSuccess={handlePhoneSuccess} />;
      default:
        return null;
    }
  }

  return (
    <PageContainer>
      <ContainerWithLogo>{renderForm()}</ContainerWithLogo>
    </PageContainer>
  );
}

export default ActivateProductKey;
