import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import prek3rdAgeLevel from 'images/envScales/prek3rdAgeLevel.svg';
import doubleCodeIcon from 'images/envScales/doubleCode.svg';
import { Assessment } from 'types/api/envScales/Assessment';
import { useMediaQuery } from 'react-responsive';
import { useDCLanguages } from 'components/dropdowns/Languages';
import AgeLevelTag from 'components/AgeLevel/AgeLevelTag';
import { getAgeLevel } from 'helpers/ageLevel';
import { useMeasureLocation } from 'components/Measure/View/useMeasureLocation';

interface HeaderProps {
  assessment: Assessment;
}

function Header({ assessment }: HeaderProps) {
  const languages = useDCLanguages();
  const isTablet = useMediaQuery({ minWidth: 1024 });
  const rangeMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const { isDetailsPage, isReviewPage } = useMeasureLocation();

  if (isDetailsPage) {
    return null;
  }

  const doubleCodeText = assessment.primary_observer
    ? i18n.ft(messages.codePrimary)
    : i18n.ft(messages.codeSecondary);

  const observationLanguage = languages.find(
    lang => lang.key === assessment.language
  );
  const ageLevelName =
    (assessment.age_level === 'Inf' ||
      assessment.age_level === 'Todd' ||
      assessment.age_level === 'Pre-K') &&
    getAgeLevel(assessment.age_level).name;

  const titleStyle = 'font-bold text-[#3C3F42] mr-1.5';

  function getTeacherNames() {
    return [
      ...assessment.teachers,
      ...assessment.educators.map(e => e.name)
    ].join(', ');
  }

  return isReviewPage ? (
    <div className="p-4 xl:px-20 bg-[#F0FBFF]">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-[1fr_2fr_0.5fr] lg:gap-2">
        {!isTablet && !rangeMediumScreen && (
          <div className="flex justify-between">
            {assessment.age_level === 'Inf' ||
            assessment.age_level === 'Todd' ||
            assessment.age_level === 'Pre-K' ? (
              <AgeLevelTag abbreviation={assessment.age_level} className="!m-0">
                {ageLevelName}
              </AgeLevelTag>
            ) : (
              <img
                src={prek3rdAgeLevel}
                alt={i18n.ft(messages.classAssessment)}
              />
            )}
            {assessment.double_coding && (
              <img src={doubleCodeIcon} alt={doubleCodeText} />
            )}
          </div>
        )}
        {rangeMediumScreen && (
          <>
            <div className="flex">
              {assessment.age_level === 'Inf' ||
              assessment.age_level === 'Todd' ||
              assessment.age_level === 'Pre-K' ? (
                <AgeLevelTag
                  abbreviation={assessment.age_level}
                  className="!m-0"
                >
                  {ageLevelName}
                </AgeLevelTag>
              ) : (
                <img
                  src={prek3rdAgeLevel}
                  alt={i18n.ft(messages.classAssessment)}
                />
              )}
            </div>
            <div>
              {assessment.double_coding && (
                <img src={doubleCodeIcon} alt={doubleCodeText} />
              )}
            </div>
          </>
        )}
        <div className="flex">
          <div className={titleStyle}>{i18n.ft(messages.observationDate)}</div>
          <div>
            {assessment && moment(assessment.taken_at).format('MM/DD/YYYY')}
          </div>
        </div>
        <div className="flex whitespace-normal">
          <div className={titleStyle}>{i18n.ft(messages.center_name)}</div>
          <div>{assessment.center_name}</div>
        </div>
        {isTablet && (
          <div className="flex justify-center">
            {assessment.age_level === 'Inf' ||
            assessment.age_level === 'Todd' ||
            assessment.age_level === 'Pre-K' ? (
              <AgeLevelTag abbreviation={assessment.age_level} className="!m-0">
                {ageLevelName}
              </AgeLevelTag>
            ) : (
              <img
                src={prek3rdAgeLevel}
                alt={i18n.ft(messages.classAssessment)}
              />
            )}
          </div>
        )}
        {assessment.job_id ? (
          <div className="flex">
            <div className={titleStyle}>{i18n.ft(messages.jobId)}</div>
            <div>{assessment.job_id}</div>
          </div>
        ) : (
          <div className="flex"></div>
        )}
        <div className="flex whitespace-normal">
          <div className={titleStyle}>{i18n.ft(messages.classroom)}</div>
          <div>{assessment.classroom_name}</div>
        </div>
        {isTablet && (
          <div className="flex justify-center">
            {assessment.double_coding && (
              <img src={doubleCodeIcon} alt={doubleCodeText} />
            )}
          </div>
        )}

        <div className="flex">
          <div className={titleStyle}>{i18n.ft(messages.language)}</div>
          <div>{observationLanguage?.text}</div>
        </div>
        <div className="flex">
          <div className={titleStyle}>{i18n.ft(messages.teachers)}</div>
          <div>{getTeacherNames()}</div>
        </div>
      </div>
    </div>
  ) : (
    <div className="p-4 xl:px-20 bg-[#F0FBFF]">
      <div className="grid grid-cols-2 gap-2">
        <div className="flex">
          <div className={titleStyle}>{i18n.ft(messages.observationDate)}</div>
          <div>
            {assessment && moment(assessment.taken_at).format('MM/DD/YYYY')}
          </div>
        </div>
        <div className="flex whitespace-normal">
          <div className={titleStyle}>{i18n.ft(messages.center_name)}</div>
          <div>{assessment.center_name}</div>
        </div>
        {assessment.job_id ? (
          <div className="flex">
            <div className={titleStyle}>{i18n.ft(messages.jobId)}</div>
            <div>{assessment.job_id}</div>
          </div>
        ) : (
          <div className="flex">
            <div className={titleStyle}>
              {i18n.ft(messages.classAssessment)}
            </div>
            <div>
              {assessment.age_level === 'PK-3rd'
                ? i18n.ft(messages.prek3rd)
                : assessment.age_level}
            </div>
          </div>
        )}
        <div className="flex whitespace-normal">
          <div className={titleStyle}>{i18n.ft(messages.classroom)}</div>
          <div>{assessment.classroom_name}</div>
        </div>
      </div>
    </div>
  );
}

export default Header;
