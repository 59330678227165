import i18n from 'helpers/i18n';
import messages from './messages';
import packageIcon from 'images/package.svg';
import { useCallback, useContext, useEffect, useState } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import RoutesHelper from 'helpers/routes';
import { useLocation } from 'react-router-dom';
import { Video } from '../types';
import { LearningResourcesContext } from 'pages/LearningResources/LearningResourcesContext';
import { toastError } from 'components/Toast';
import { ResourceType } from 'types/api/LearningResource';

interface PlaylistItemProps {
  handleClick: (id: number, type: ResourceType) => void;
  percentage: number;
  numberOfResources: number;
  video: Video;
}

function PlaylistItem({
  percentage,
  numberOfResources,
  handleClick,
  video
}: PlaylistItemProps) {
  const { roles = [] } = useCurrentUser();
  const [isChecked, setIsChecked] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  const learningResourcesContext = useContext(LearningResourcesContext);

  if (!learningResourcesContext) {
    throw new Error(
      'Learning Resources must be used within a VideoTagsProvider'
    );
  }

  const { recommendedVideosArray, setRecommendedVideosArray } =
    learningResourcesContext;

  const isCheckedItem = useCallback(() => {
    return recommendedVideosArray.find(item => item.id === video.id);
  }, [recommendedVideosArray, video.id]);

  useEffect(() => {
    setIsChecked(isCheckedItem() ? true : false);
  }, [isCheckedItem, recommendedVideosArray]);

  function handleChangeOnClick(e: any) {
    e.stopPropagation();
    if (e.target.type === undefined) {
      const itemFound = recommendedVideosArray.find(
        item => item.id === video.id
      );

      setIsChecked(prev => {
        let newCheckedStatus = prev;

        if (prev) {
          if (!!itemFound) {
            setRecommendedVideosArray([
              ...recommendedVideosArray.filter(item => item.id !== video.id)
            ]);
            newCheckedStatus = false;
          }
        } else {
          if (!!itemFound) {
            newCheckedStatus = prev;
            toastError({
              description: 'Error'
            });
          } else {
            setRecommendedVideosArray([
              ...recommendedVideosArray,
              {
                name: video.name || '',
                id: video.id || 0,
                videoURI: video.videoURI || '',
                estimatedTime: video.estimatedTime || '',
                type: video.type || 'learning_resource'
              }
            ]);
            newCheckedStatus = true;
          }
        }

        return newCheckedStatus;
      });
    }
  }

  function handleCheckboxChange(e: any) {
    e.stopPropagation();
    if (e.target.type === 'checkbox') {
      setIsChecked(!e.target.checked);
    }
  }

  function renderCoachSection() {
    return (
      <div
        className="hover:bg-[#E6F5FA] text-right py-2 color=[#3C3F42] rounded-b-lg"
        onClick={handleChangeOnClick}
      >
        <span>
          {`${i18n.ft(messages.recommend)}`}
          <label
            className="checkbox-container inline-block relative pl-8 mb-3 cursor-pointer text-lg"
            htmlFor={`${video.id}`}
          >
            <input
              type="checkbox"
              id={`${video.id}`}
              checked={isChecked}
              onChange={handleCheckboxChange}
              className="absolute opacity-0 cursor-pointer h-0 w-0"
            />
            <span
              className={`checkmark absolute top-0 left-0 h-3 w-3 ml-2 hover:bg-[#e6f5fa] border border-slate-500 ${
                isChecked
                  ? 'bg-[#0A9CCA] hover:bg-[#0A9CCA]'
                  : '!hover:bg-[#0A9CCA]'
              }`}
            >
              {isChecked && (
                <svg
                  viewBox="0 0 24 24"
                  className="w-full h-full fill-current text-white"
                >
                  <polyline
                    points="20 6 9 17 4 12"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                </svg>
              )}
            </span>
          </label>
        </span>
      </div>
    );
  }

  function isCoach() {
    return (
      roles.includes('lr_coach') &&
      currentPath.includes(RoutesHelper.getPath('coach-learning-resources'))
    );
  }

  return (
    <div
      className={`min-w-[286px] mt-4 relative rounded-lg shadow-md ${isChecked ? 'bg-[#E6F5FA] border-2 border-[#077699]' : 'bg-white border border-gray-200'}`}
      style={{ boxShadow: '0px 6px 25px 0px #0000003D' }}
    >
      <div
        className="relative z-10 min-w-[296px] bg-white rounded-lg h-fit cursor-pointer sm:max-h-[296px] md:max-h-[352px] lg:max-h-[315px] xl:max-h-[282px]"
        onClick={() => handleClick(video.id, video.type || 'learning_resource')}
      >
        <div className="snap-start flex flex-shrink-0 relative" tabIndex={0}>
          <img
            className="w-full max-w-full rounded-t-lg object-cover md:!h-[249px] lg:!h-56 2xl:!h-40"
            src={`https://cdnsecakmi.kaltura.com/p/966871/thumbnail/entry_id/${video.videoURI}/width/600`}
            alt="Thumbnail"
          />
          <div className="mt-2 ml-2 absolute top-0 left-0 bg-white text-black px-2 py-1 rounded text-xs font-semibold">
            {`${numberOfResources} ${i18n.ft(messages.resources)}`}
          </div>
        </div>
      </div>

      <div className="mx-4 my-3">
        <h3 className="text-lg font-semibold my-2 line-clamp-2 leading-5 h-10">
          {video.name}
        </h3>
        <span className="inline-block bg-[#636568] text-white font-semibold text-sm px-2 py-1 rounded-md mt-2 mb-4">
          <img src={packageIcon} alt="" className="inline" />{' '}
          {`${i18n.ft(messages.playlist)}`}
        </span>
        <div>
          <div className="bg-[#D8D9D9] rounded-xl shadow-sm overflow-hidden mb-2">
            <div className="relative h-4 flex items-center justify-center">
              <div
                className={`absolute top-0 bottom-0 left-0 rounded-lg bg-[#077699]`}
                style={{ width: `${percentage}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`grid grid-cols-2 rounded-b-lg ${isCoach() ? ' hover:bg-[#E6F5FA]' : 'mb-2'} `}
        onClick={isCoach() ? handleChangeOnClick : () => {}}
      >
        <span className="font-bold ml-4 content-center self-center">{`${percentage}% ${i18n.ft(
          messages.complete
        )}`}</span>
        {isCoach() && renderCoachSection()}
      </div>
      <div className="absolute inset-x-0 top-0 flex justify-center">
        <div
          className={`w-10/12 h-6 rounded-t-lg shadow-md -mt-4 ${isChecked ? 'bg-[#E6F5FA] border-2 border-[#077699]' : ' bg-white'}`}
          style={{ boxShadow: '0px -2px 4px 0px #00000040' }}
        ></div>
      </div>

      <div className="absolute inset-x-0 top-0 flex justify-center">
        <div
          className={`w-11/12 h-6 rounded-t-lg shadow-md -mt-2 ${isChecked ? 'bg-[#E6F5FA] border-2 border-[#077699]' : 'bg-white'}`}
          style={{ boxShadow: '0px -2px 4px 0px #00000040' }}
        ></div>
      </div>
    </div>
  );
}

export default PlaylistItem;
