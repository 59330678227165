import { useNode } from './useNode';

export function NodeDisclosureButton() {
  const { node, isOpen, setIsOpen } = useNode();

  if (node.children.length === 0) {
    return null;
  }

  const handleExpandClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleExpandKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();
  };

  return (
    <button
      onClick={handleExpandClick}
      onKeyDown={handleExpandKeyDown}
      className="w-8 h-8 flex items-center justify-center hover:bg-action-tint rounded"
    >
      {isOpen ? (
        <i className="fa-solid fa-caret-up" />
      ) : (
        <i className="fa-solid fa-caret-down" />
      )}
    </button>
  );
}
