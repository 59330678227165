const PATH = 'fe.components.training_history.learning_resources';

const messages = {
  learningResources: {
    key: `${PATH}.learning_resources`,
    defaultValue: 'Learning Resources'
  },
  resourcesCount: {
    key: `${PATH}.resources_count`,
    defaultValue: '{{count}} Resources'
  },
  fromCoach: {
    key: `${PATH}.from_coach`,
    defaultValue: 'Recommendations from coach'
  },
  fromScores: {
    key: `${PATH}.from_scores`,
    defaultValue: 'Recommendations based on scores'
  },
  fromHistory: {
    key: `${PATH}.from_history`,
    defaultValue: 'Recommendations based on viewing history'
  },
  selfGuided: {
    key: `${PATH}.self_guided`,
    defaultValue: 'Self-guided exploration'
  },
  totalTime: {
    key: `${PATH}.total_time`,
    defaultValue: 'Total Time Spent'
  },
  totalCompleted: {
    key: `${PATH}.total_completed`,
    defaultValue: 'Total Completed'
  },
  recommendedByCoach: {
    key: `${PATH}.recommended_by_coach`,
    defaultValue: 'Recommended by your Coach'
  },
  recommendedAndCompleted: {
    key: `${PATH}.recommended_and_completed`,
    defaultValue: '{{recommended}} recommended, {{completed}} completed'
  },
  completed: {
    key: `${PATH}.completed`,
    defaultValue: '{{percentage}}% completed'
  },
  noRecommendations: {
    key: `${PATH}.no_recommendations`,
    defaultValue: 'No recommendations'
  }
};

export default messages;
